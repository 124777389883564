.itSupport__label {
  padding-bottom: 10px !important;
  text-align: center;
}

.itSupport__radioBtnContainer {
  margin-left: -20px !important;
  padding-bottom: 30px;
}

.itSupport__selectHKSupport {
  height: 55px;
  width: 100%;
  border-color: rgb(196, 196, 196);
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  -webkit-appearance: menulist;
  font-size: 16px;
  color: black;
}

.itSupport__button {
  width: 200px !important;
  height: 40px;
  margin: 20px auto !important;
  background-color: var(--sportson-yellow-color);
  border-radius: 5px !important;
  border: none !important;
  display: block !important;
}

.itSupport__select {
  height: 55px;
  width: 100%;
  border-color: rgb(196, 196, 196);
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 10px;
  -webkit-appearance: menulist;
  font-size: 16px;
  color: black;
}

.supportLink {
  display: inline-block;
  width: 110px;
  text-align: center;
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  color: var(--sportson-darkgrey-color);
}

.supportLink:hover {
  color: var(--sportson-darkgrey-color);
}

a:hover {
  text-decoration: none !important;
}

.supportLinkClicked {
  border-bottom: 5px solid var(--sportson-grey-color);
  padding-bottom: 25px;
}

.MuiPaper-rounded {
  border-radius: 0 !important;
}

.MuiDialog-paperWidthSm {
  max-width: 900px !important;
  width: 900px;
}

.tabSupportIcon {
  position: relative;
  top: -15px;
  left: 67px;
  color: var(--sportson-darkgrey-color);
}

Link {
  width: 110px;
  text-align: center;
}