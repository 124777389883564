.printButton {
  border-radius: 5px;
  height: 40px;
  width: 140px;
  margin-right: 10px;
}

.buttonText {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 12px;
}
