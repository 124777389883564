.bolag {
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    height: 100%;
    width: 825px;
    margin: 0 auto;
    flex: 0.9;
}

.bolag__inputContainer {
    padding-top: 176px;
    border-top: 1px;
    padding-bottom: 100px;
}

.bolag__header {
    margin-bottom: 20px;
}

.bolag__textFields {
    margin: 0 auto 20px;
}

.bolag__switchButton {
    background-color: white;
    border: none;
    font-size: 30px;
}

.bolag__switchButton:hover, .bolag__addNewContactButton:hover {
    color: grey;
}

.bolag__conditionsButton {
    display: block;
    margin: 20px auto;
    background-color: var(--sportson-yellow-color);
    border: 1px solid black;
    border-radius: 5px;
    height: 35px;
    padding-right: 10px;
    padding-left: 10px;
}

.bolag__confirmButton {
    background-color: var(--sportson-yellow-color);
    border: 1px solid black;
    color: var(--sportson-darkgrey-color);
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    height: 40px;
    margin-bottom: 20px;
    padding-right: 25px;
    padding-left: 25px;
}

.bolag__addNewContactButton {
    border: none;
    background-color: white;
    cursor: pointer;
    padding: 0 20px;
}

.bolag__checkboxText {
    position: relative;
    top: 5px;
    left: 5px;
    margin-right: 20px;
}

.bolag__warningText {
    position: relative;
    top: -5px !important;
}

.bolag__flexCenter {
    display: flex;
    justify-content: center;
    align-content: center;
}

.bolag__date {
    height: 40px;
    width: 160px;
    font-size: 16px;
    text-align: center;
}

.bolag__dateInputFields {
    border-radius: 10px;
    color: grey;
    margin-bottom: 20px;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.bolag__securityAgreementDiv {
    padding-top: 30px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
}

.bolag__flexRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.bolag__portalDiv {
    padding: 10px;
    border-radius: 5px;
    background-color: var(--sportson-lightgrey-color);
}

.bolag__sportsonComments {
    display: flex;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.bolag__sportsonComments > h5 {
    font-size: 16px;
}

.bolag__comments {
    display: flex;
    flex-direction: column;
    padding: 5px;
    padding-right: 15px !important;
    padding-top: 15px !important;
    background-color: var(--sportson-lightgrey-color);
    justify-content: left;
    border-radius: 10px;
    margin-bottom: 15px;
}

.bolag__comment > h5 {
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
}

.bolag__comment > h6 {
    color: var(--sportson-darkgrey-color);
}

.bolag__commentIcon {
    color: var(--sportson-yellow-color);
    position: relative;
    top: 10px;
    left: 10px;
}

.bolag__expandButton {
    border: none;
    background: none;
    outline: none;
    transform: scale(2);
    padding-top: 5px;
}

.bolag__commentButton {
    position: relative;
    left: 15px;
    width: 35px;
    height: 30px;
    margin-right: 5px;
    margin-bottom: 15px;
    background-color: white;
    border-radius: 5px;
    border: 2px solid black;
    font-size: 14px;
    text-align: center;
    float: left;
}

.bolag__editButton {
    position: relative;
    bottom: 10px;
    left: 15px;
    margin-top: 15px;
    margin-right: 25px;
    width: 200px;
    height: 35px;
    background-color: white;
    border-radius: 5px;
    border: 2px solid black;
    font-size: 14px;
    text-align: left;
}

.bolag__iconAndText {
    position: relative;
    top: -5px;
    left: -5px;
}

.bolag__icons {
    position: relative;
    top: 8px;
    margin-left: 10px;
}

.bolag__theShops,
.bolag__theExternalPartners {
    margin-bottom: 120px;
}

.bolag__conditionsButtonAlignment {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bolag__iconInButton {
    margin-left: 10px;
    font-weight: bolder;
    transform: scale(1.20);
    border: 2px solid black;
    border-radius: 50px;
}

.bolag__disabledField {
    position: relative;
    top: -50px;
    left: 405px;
}

.bolag__lockIcon {
    position: absolute;
}

.bolag__disabledFieldTooltip {
    position: relative;
    top: -75px;
    left: 25px;
}

.bolag__disabledFieldCheckboxWithoutTooltip {
    position: relative;
    top: -65px;
    left: 25px;
}

.bolag__disabledButton {
    position: relative;
    top: -50px;
    left: 65px;
}

.bolag__disabledText {
    position: relative;
    top: 10px;
}

.bolag__fileUploadDiv {
    margin: 20px auto;
    padding-top: 20px;
    width: 480px;
    border: 1px solid lightgray;
    border-radius: 5px;
    background-color: white;
}

.bolag__fileUploadDivLegend {
    margin-top: 18.5px;
    color: grey;
    font-size: 16px;
}

.bolag__fileUploadButtonsDiv {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-top: 15px;
}

.bolag__fileInputButton {
    position: relative;
    top: 10px;
    left: 10px;
}
.bolag__fileUploadButtons {
    border: 1px solid grey;
    border-radius: 5px;
    margin: 10px;
}
