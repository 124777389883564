.body {
  font-family: FSEmericCore, sans-serif;
}

.modalConfirmWithButtons__header {
  width: 550px !important;
  padding: 1rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-left: 10px;
}

.modalConfirmWithButtons__header > h2 {
  font-size: 16px;
}
.modalConfirmWithButtons__topHeader > span {
  font-size: 1.5rem !important;
  cursor: pointer !important;
  margin-right: 15px !important;
}

.modalConfirmWithButtons__body {
  margin: 0 auto;
}

.modalConfirmWithButtons__button {
  width: 200px !important;
  height: 40px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: var(--sportson-yellow-color);
  border-radius: 5px !important;
  border: none !important;
  cursor: pointer;
}
