.formansfonster {
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    height: 100%;
    width: 825px;
    margin: 0 auto;
    flex: 0.9;
}

.formansfonster__inputContainer {
    padding-top: 176px;
    border-top: 1px;
    padding-bottom: 100px;
}

.formansfonster__header {
    margin-bottom: 20px;
}

.formansfonster__textFields {
    margin: 0 auto 20px;
}

.formansfonster__switchButton {
    background-color: white;
    border: none;
    font-size: 30px;
}

.formansfonster__switchButton:hover {
    color: grey;
}

.formansfonster__demoButton {
    display: block;
    margin: 20px auto;
    background-color: var(--sportson-yellow-color);
    border: 1px solid black;
    border-radius: 5px;
    height: 35px;
    padding-right: 10px;
    padding-left: 10px;
}

.formansfonster__confirmButton {
    display: block;
    margin: 20px auto;
    background-color: var(--sportson-yellow-color);
    border: 1px solid black;
    color: var(--sportson-darkgrey-color);
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    height: 40px;
    padding-right: 25px;
    padding-left: 25px;
}

.formansfonster__createAndCopyButtons {
    display: inline-block;
    background-color: var(--sportson-yellow-color);
    border: 1px solid black;
    color: var(--sportson-darkgrey-color);
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    height: 40px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    padding-right: 25px;
    padding-left: 25px;
}

.formansfonster__date {
    height: 40px;
    width: 160px;
    font-size: 16px;
    text-align: center;
}

.formansfonster__dateInputFields {
    border-radius: 10px;
    color: grey;
    margin-bottom: 20px;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.formansfonster__securityAgreementDiv {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.formansfonster__invoiceDiv {
    padding: 10px;
    border-radius: 5px;
    background-color: var(--sportson-lightgrey-color);
}

.formansfonster__fileUploadDiv {
    margin: 20px auto;
    padding-top: 20px;
    width: 480px;
    border: 1px solid lightgray;
    border-radius: 5px;
    background-color: white;
}

.formansfonster__fileUploadDivLegend {
    margin-top: 18.5px;
    color: grey;
    font-size: 16px;
}

.formansfonster__fileUploadButtonsDiv {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-top: 15px;
}

.formansfonster__fileInputButton {
    position: relative;
    top: 10px;
    left: 10px;
}
.formansfonster__fileUploadButtons {
    border: 1px solid grey;
    border-radius: 5px;
    margin: 10px;
}

.formansfonster__checkboxText {
    position: relative;
    top: 5px;
    left: 5px;
    margin-right: 20px;
}

.formansfonster__warningText {
    position: relative;
    top: -5px !important;
}

.formansfonster__flexCenter {
    display: flex;
    justify-content: center;
    align-content: center;
}

.formansfonster__flexRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.formansfonster__invoiceDateDiv {
    position: relative;
    left: -60px;
}

.formansfonster__invoiceDateDiv > label {
    position: relative;
    left: 20px;
}

.formansfonster__invoiceNumberDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.formansfonster__invoiceNumberDiv > input {
    height: 40px;
    width: 160px;
    font-size: 16px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 20px;
}

.formansfonster__sportsonComments {
    display: flex;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.formansfonster__sportsonComments > h5 {
    font-size: 16px;
}

.formansfonster__comments {
    display: flex;
    flex-direction: column;
    padding: 5px;
    padding-right: 15px !important;
    padding-top: 15px !important;
    background-color: var(--sportson-lightgrey-color);
    justify-content: left;
    border-radius: 10px;
    margin-bottom: 15px;
}

.formansfonster__comment > h5 {
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
}

.formansfonster__comment > h6 {
    color: var(--sportson-darkgrey-color);
}

.formansfonster__commentIcon {
    color: var(--sportson-yellow-color);
    position: relative;
    top: 10px;
    left: 10px;
}

.formansfonster__commentIcon {
    color: var(--sportson-yellow-color);
    position: relative;
    top: 10px;
    left: 10px;
}

.formansfonster__expandButton {
    border: none;
    background: none;
    outline: none;
    transform: scale(2);
    padding-top: 5px;
}

.formansfonster__commentButton {
    position: relative;
    left: 15px;
    width: 35px;
    height: 30px;
    margin-right: 5px;
    margin-bottom: 15px;
    background-color: white;
    border-radius: 5px;
    border: 2px solid black;
    font-size: 14px;
    text-align: center;
    float: left;
}

.formansfonster__editButton {
    position: relative;
    bottom: 10px;
    left: 15px;
    margin-top: 15px;
    margin-right: 25px;
    width: 200px;
    height: 35px;
    background-color: white;
    border-radius: 5px;
    border: 2px solid black;
    font-size: 14px;
    text-align: left;
}

.formansfonster__iconAndText {
    position: relative;
    top: -5px;
    left: -5px;
}

.formansfonster__icons {
    position: relative;
    top: 8px;
    margin-left: 10px;
}

.bolag__conditionsButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.formansfonster__iconInButton {
    margin-left: 10px;
    font-weight: bolder;
    transform: scale(1.20);
    border: 2px solid black;
    border-radius: 50px;
}

.formansfonster__theShops,
.formansfonster__theExternalPartners {
    margin-bottom: 120px;
}

.formansfonster__cancelButton {
    display: block;
    margin: 20px auto;
    background-color: red;
    border: 1px solid black;
    border-radius: 5px;
    height: 35px;
    padding-right: 10px;
    padding-left: 10px;
}

.formansfonster__cancelledBenefitWindowBanner {
    height: 40px;
    width: 100%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
}

.formansfonster__disabledField {
    position: relative;
    top: -60px;
    left: 400px;
}

.formansfonster__lockIcon {
    position: absolute;
}

.formansfonster__disabledFieldTooltip {
    position: relative;
    top: -75px;
    left: 25px;
}

.formansfonster__disabledFieldCheckboxWithoutTooltip {
    position: relative;
    top: -65px;
    left: 25px;
}

.formansfonster__disabledButton {
    position: relative;
    top: 25px;
    left: -145px;
}

.formansfonster__disabledText {
    position: relative;
    top: 10px;
}