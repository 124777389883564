.orderFormReturn {
  display: flex;
  flex-direction: column;
}

.orderFormReturn__Container {
  display: flex;
  justify-content: center;
  background-color: var(--sportson-lightgrey-color);
  height: 100%;
  max-width: 1800px;
  width: 800px;
}

.orderFormReturn__header {
  width: 100%;
  padding-top: 10px;
}

.orderFormReturn__title > h1 {
  font-weight: 600;
  text-align: left;
  margin-left: -40px;
  font-size: 27px;
}

.orderFormReturn__subHeader {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-left: 0;
  text-align: left;
}

.orderFormReturn__orderContainer {
  display: flex;
  justify-content: space-between;
}

.orderFormReturn__orderNr {
  padding-left: 50px;
}

.orderFormReturn__orderNr > h4 {
  padding-top: 10px;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 400;
}

.orderFormReturn__orderNr > span,
.orderFormReturn__datum > span,
.orderFormReturn__priceContainer > span {
  font-size: 16px;
  color: var(--sportson-grey-color);
}

.orderFormReturn__datum > h4 {
  padding-top: 10px;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 400;
}

.orderFormReturn__priceContainer > h3 {
  font-size: 24px;
  padding-top: 10px;
  font-weight: 600;
}

.orderFormReturn__title {
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
}

.orderFormReturn__buttonWrap {
  display: flex;
}

.orderFormReturn__horizontalLine {
  margin-top: 40px;
  border-color: var(--sportson-lightgrey-color);
  color: var(--sportson-lightgrey-color);
  margin-bottom: 20px;
}

.orderFormReturn__InfoContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
}

.orderFormReturn__InfoContainer > h3 {
  font-size: 24px;
  font-weight: 500;
}

.orderFormReturn__vy {
  display: flex;
  align-items: center;
}

.orderFormReturn__vy > span {
  margin-right: 20px;
  color: var(--sportson-grey-color);
}

.orderFormReturn__toggleBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.orderFormReturn__tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--sportson-yellow-color);
  width: 70px;
  height: 20px;
  padding: 5px;
  border: 1px solid var(--sportson-grey-color);
}

.active-tabs {
  background-color: white !important;
}

.orderFormReturn__content {
  display: none;
}

.active-content {
  display: block;
}

.orderFormReturn__titelsKund {
  display: flex;
  justify-content: space-between;
  padding-left: 200px;
  padding-right: 300px;
  padding-bottom: 25px;
  color: var(--sportson-grey-color);
  text-align: left;
}

.orderFormReturn__productTextKund {
  position: absolute;
  font-size: 16px;
  margin-left: -50px;
}

.orderFormReturn__artNrTextKund {
  position: absolute;
  margin-left: 210px;
  font-size: 16px;
}

.orderFormReturn__priceTextKund {
  position: absolute;
  margin-left: 422px;
  font-size: 16px;
}

.orderFormReturn__titelsButik {
  display: flex;
  justify-content: space-between;
  padding-left: 150px;
  padding-right: 300px;
  padding-bottom: 20px;
  color: var(--sportson-grey-color);
  text-align: left;
  font-size: 13px;
}

.orderFormReturn__productTextButik {
  position: absolute;
  font-size: 14px;
  margin-left: -30px;
}

.orderFormReturn__artNrTextButik {
  position: absolute;
  margin-left: 120px;
  font-size: 14px;
}

.orderFormReturn__priceTextButik {
  position: absolute;
  margin-left: 245px;
  font-size: 14px;
}

.orderFormReturn__shopPriceTextButik {
  position: absolute;
  margin-left: 377px;
  font-size: 14px;
}

.orderFormReturn__newPriceTextButik {
  position: absolute;
  margin-left: 485px;
  font-size: 14px;
}

.orderFormReturn__tgTextButik {
  position: absolute;
  margin-left: 600px;
  font-size: 14px;
}

.orderFormReturn__rowContainer2 {
  display: flex;
  justify-content: center;
}

.orderFormReturn__sendBtn {
  border: 1px solid black;
  margin-left: 700px;
  margin-top: 40px;
  margin-bottom: 60px;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 1030px) {
  .orderFormReturn__title,
  .orderFormReturn__subHeader,
  .orderFormReturn__InfoContainer > h3 {
    margin-left: 40px;
  }

  .orderFormReturn__buttonWrap,
  .orderFormReturn__priceContainer,
  .orderFormReturn__vy {
    margin-right: 40px;
  }

  .orderFormReturn__tgTextButik {
    margin-left: 775px;
  }
}