.body {
  font-family: FSEmericCore, sans-serif;
}

.modal__header {
  width: 550px !important;
  padding: 1rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-left: 10px !important;
}

.modal__topHeader > span {
  font-size: 1.5rem !important;
  cursor: pointer !important;
  margin-right: 15px !important;
}

.modal__button {
  width: 310px !important;
  height: 40px;
  margin: 20px auto;
  background-color: var(--sportson-yellow-color);
  border-radius: 5px !important;
  border: none !important;
  cursor: pointer;
}
