.formanscykelFonster {
    width: 100%;
    background-color: white;
    padding: 25px;
}

.formanscykelFonster h4,
.formanscykelFonster h5 {
    font-size: 16px;
}

.formanscykelFonster__table {
    display: table;
    width: 100%;
    border-bottom: 1px solid grey;
    padding-top: 5px;
    padding-bottom: 5px;
}

.formanscykelFonster__rowHeader {
    border-bottom: 1px solid grey;
}

.formanscykelFonster__rowColumnsDate {
    width: 200px;
}

.formanscykelFonster__rowColumns {
    width: 14%;
}

.formanscykelFonster__rowGray {
    background-color: var(--sportson-lightgrey-color);
}

.formanscykelFonster__row {
    cursor: pointer;
    height: 40px;
}

.formanscykelFonster__dateRange {
    background-color: black;
    color: white;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 15px;
    font-weight: bold;
}

.formanscykelFonster__month {
    color: black;
    font-size: 16px;
}

.formanscykelFonster__orderDiv {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--sportson-lightgrey-color);
}

.formanscykelFonster__searchResultsHeader {
    float: left;
    margin-bottom: 20px;
}

.formanscykelFonster__pageButton {
    border-radius: 10px;
    font-size: 20px;
    width: 50px;
    height: 50px;
    background-color: var(--sportson-lightgrey-color);
    border: 2px solid var(--sportson-grey-color);
    margin: 30px 5px 5px;
}

.formanscykelFonster__currentPage {
    text-decoration: underline;
}

.formanscykelFonster__loadingDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    margin-bottom: 200px;
}
.formanscykelFonster__loader {
    border: 16px solid var(--sportson-lightgrey-color);
    border-top: 16px solid var(--sportson-yellow-color);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.formanscykelFonster__pageButtonsDiv {
    display: flex;
    justify-content: center;
}

.formanscykelFonster__pageLimitButtonsDiv {
    position: absolute;
    right: 0;
    margin-right: 10px;
}

.formanscykelFonster__pageLimitButtonsDiv > button {
    border-radius: 5px;
    background-color: var(--sportson-lightgrey-color);
    border: 2px solid var(--sportson-grey-color);
    width: 40px;
    height: 40px;
}