.users {
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 0.9;
}

.users h4 {
    font-size: 16px;
}

.users__inputText {
    width: 350px !important;
    background-color: white;
}

.users__inputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px;
    padding-top: 176px;
    padding-bottom: 92px;
}

.users__searchBtn {
    height: 58px;
    width: 90px;
    background-color: var(--sportson-yellow-color);
    border: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: var(--sportson-darkgrey-color);
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.userList {
    position: relative;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.users__userText {
    margin-right: 100px !important;
    margin-top: 5px !important;
}

.users__userBtn {
    background-color: var(--sportson-lightgrey-color);
    border: 1px solid black;
    color: var(--sportson-darkgrey-color);
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    height: 40px;
    margin-left: 50px;
    margin-bottom: 20px;
    padding-right: 25px;
    padding-left: 25px;
}

.users__horizontalLine {
    margin-bottom: 15px;
    height: 2px;
    width: 100%;
    background-color: lightgrey;
}

.users__userList {
    width: 100%;
}

.users__tableContainer {
    height: 1225px;
}

.users__userListUsers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
    margin-left: 50px;
}

.users__userListUsers > tr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 15px;
    padding-left: 25px;
}

.users__searchResultText {
    position: absolute;
    left: 50px;
    top: -15px;
}

.users__pageButton {
    border-radius: 10px;
    font-size: 20px;
    width: 50px;
    height: 50px;
    background-color: var(--sportson-lightgrey-color);
    border: 2px solid var(--sportson-grey-color);
    margin: 5px 20px 5px 5px;
}

.users__currentPage {
    text-decoration: underline;
}

@media screen and (max-width: 1400px) {

    .users__userListUsers {
        transform: scale(0.75, 0.95);
    }
}

.users__rowColumnsHeader {
    height: 25px !important;
}

.users__rowColumns {
    text-align: left;
    width: 150px;
    min-width: 270px;
    height: 50px;
    vertical-align: middle;
}
.users__rowColumnsScroll {
    text-align: left;
    width: 200px;
    min-width: 270px;
    height: 50px;
    vertical-align: middle;
}

.users__rowColumnsHalf {
    margin-left: 200px;
    text-align: left;
    width: 112.5px;
    min-width: 175px;
    height: 50px;
    vertical-align: middle;
}

.users__rowColumnsDouble {
    text-align: left;
    width: 225px;
    min-width: 400px;
    height: 50px;
    vertical-align: middle;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

.users__loadingDiv {
    margin-top: 200px;
    margin-bottom: 200px;
}
.users__loader {
    border: 16px solid var(--sportson-lightgrey-color);
    border-top: 16px solid var(--sportson-yellow-color);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
