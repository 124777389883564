.card {
  background-color: var(--sportson-lightgrey-color) !important;
  height: 400px;
  max-width: 1200px;
  width: 900px;
  border-style: none !important;
}

.card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  background-color: var(--sportson-lightgrey-color);
}

.card__id {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.card__amount > span {
  font-size: 14px;
  font-weight: 700;
}

.card__amount > h5 {
  padding-top: 15px;
  font-size: 1.5rem;
  font-weight: 500;
}

.card__shopSelector {
  height: 40px;
  width: 180px;
  border-color: rgb(196, 196, 196);
  border-radius: 5px;
  -webkit-appearance: menulist;
  font-size: 14px;
  color: black;
  margin-left: 135px;
  margin-top: 30px;
}

.card__button {
  height: 40px;
  width: 180px;
  font-size: 14px;
  background-color: var(--sportson-yellow-color);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 30px;
}

.card__container {
  padding: 30px;
  background-color: white;
  border-radius: 10px;
}

.card__topContainer {
  /* padding: 30px 50px 20px; */
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-height: 72.2px;
}

.card__amount {
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
}

.card__col {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
}

.card__col1 {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.card__title {
  padding-bottom: 10px;
  font-size: 1rem;
  color: var(--sportson-darkgrey-color);
}

.card__title__dropdown {
  position: relative;
  right: -310px;
  top: -25px;
}

.card__text {
  font-size: 1.3rem;
  padding-bottom: 7px;
}

.card__currentSum {
  font-size: 1.9rem !important;
}

.card__bottomContainer {
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 72.2px;
  margin-top: 14.2px;
}

.card__hr {
  position: relative;
  left: -30px;
  border: 2px solid var(--sportson-lightgrey-color);
  width: 898px;
  margin-top: 12.2px;
}
.card__isUsed {
  width: 300px;
}