.body {
  font-family: FSEmericCore, sans-serif;
}

.modal__BG__body {
  padding-bottom: 100px !important;
}

.modal__header {
  width: 550px !important;
  padding: 1rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-top: 0 !important;
  margin-left: 10px;
  margin-bottom: 0 !important;
  font-size: 16px !important;
}

.modal__dateHeader {
  width: 550px !important;
  padding-left: 1rem !important;
  display: flex !important;
  font-size: 16px !important;
  margin-left: 10px;
  color: var(--sportson-darkgrey-color);
}
.modal__searchField {
  margin-left: 1.5rem !important;
  background-color: white !important;
  border-radius: 10px !important;
  margin-bottom: 5px !important;
}

.modal__dateRange {
  display: inline-block;
  background-color: black;
  color: white;
  border-radius: 5px;
  margin-left: 26px;
  width: 300px;
  height: 40px;
  margin-bottom: 10px !important;
}

.modal__topHeaderBenefitWindow {
  background-color: lightgrey;
}

.modal__topHeaderBenefitWindow > span {
  font-size: 1.5rem !important;
  cursor: pointer !important;
  margin-right: 15px !important;
}

span > h2 {
  font-size: 16px;
  text-align: center;
}

.modal__BG__buttonDiv {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  margin: 0 auto;
}

.modal__BG__button {
  width: 200px !important;
  height: 75px;
  margin: 10px;
  background-color: var(--sportson-yellow-color);
  border-radius: 5px !important;
  border: none !important;
  cursor: pointer;
}

.modal__BG__closeButton {
  margin: 20px auto;
}

.modal__BG {
  background-color: lightgrey;
  border-radius: 10px;
  padding: 20px;
}

.modal__expandButton {
  float: right;
  border: none;
  background: none;
  outline: none;
  transform: scale(1.5);
}

.modal__expandCommentButton {
  position: relative;
  top: 10px;
  border: none;
  background: none;
  outline: none;
  transform: scale(1.5);
}

.modal__icons {
  position: relative;
  top: 9px;
  left: 2px;
}

.modal__hr {
  margin-bottom: 8px;
  border-bottom: 0.5px solid var(--sportson-grey-color);
}

.modal__BG__orderLink {
  background: none;
  border: none;
  color: black;
  margin: 5px;
}

.modal__BG__orderLink:hover {
  color: var(--sportson-grey-color) !important;
  cursor: pointer;
}

.modal__BG__comment {
  background-color: white;
  border-radius: 10px;
}

.modal__BG__hide {
  display: none !important;
}

.modal__BG__name {
  position: relative;
  top: 10px;
  float: right;
}