.body {
  font-family: FSEmericCore, sans-serif;
}

.modal__editComment__body {
  padding-bottom: 50px !important;
}

.modal__header {
  width: 550px !important;
  padding: 1rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-left: 10px;
}

.modal__topHeader > span {
  font-size: 1.5rem !important;
  cursor: pointer !important;
  margin-right: 15px !important;
}

.modal__editComment__input {
  margin-top: 10px !important;
}

.modal__editComment__button {
  width: 200px !important;
  height: 40px;
  margin-left: 365px;
  margin-bottom: 20px;
  background-color: var(--sportson-yellow-color);
  border-radius: 5px !important;
  border: none !important;
  cursor: pointer;
}

.MuiInputBase-input {
  font-size: 18px !important;
}

