.articleTextfields {
  padding-top: 20px;
  height: 100vh;
  padding-left: 350px;
}

.articleTextfields__textFieldContainer {
  display: flex !important;
  flex-direction: column !important;
  width: 500px !important;
}

.articleTextfields__textfield {
  display: flex;
  flex-direction: column;
  margin: 10px !important;
  background-color: white;
}

.articleTextfields__commentBox {
  width: 480px;
  /* margin-left: -350px !important; */
  background-color: white;
  margin: 10px !important;
}

.articleTextfields__priceContainer {
  display: flex !important;
  width: 500px !important;
  justify-content: space-between;
}

.articleTextfields__shopSelectorFull {
  height: 55px;
  width: 96.3%;
  border-color: rgb(196, 196, 196);
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 10px;
  -webkit-appearance: menulist;
  font-size: 16px;
  color: black;
  padding-left: 10px;
}

.articleTextfields__sendBtn {
  margin-top: 40px;
  margin-bottom: 40px;
  height: 56px;
  width: 200px;
  border: 0.5px solid var(--sportson-yellow-color);
  border-radius: 5px;
  font-weight: 800;
  font-size: 16px;
  background-color: var(--sportson-yellow-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 140px;
}

.articleTextfields__optionText {
  -webkit-appearance: none;
  color: black;
}

.articleTextfields__shopSelector {
  height: 55px;
  width: 37%;
  border-color: rgb(196, 196, 196);
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 16px;
  color: black;
  padding-left: 10px;
  -webkit-appearance: menulist;
}

.articleTextfields__selectLabels {
  position: relative;
  color: var(--sportson-grey-color);
  background-color: var(--sportson-lightgrey-color);
  font-size: 0.75em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  bottom: 60px;
  left: 19px;
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
}
.articleTextfields__butikLabel {
  position: relative;
  color: var(--sportson-grey-color);
  background-color: var(--sportson-lightgrey-color);
  font-size: 0.75em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  width: fit-content;
  height: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  right: 200px;
  top: 5px;
}
.articleTextfields__butikSelect,
select {
  background-color: var(--sportson-lightgrey-color);
}

select:focus {
  border-color: #3f51b5;
}
select:focus + label {
  color: #3f51b5;
}