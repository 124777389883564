.body {
  font-family: FSEmericCore, sans-serif;
}

.modal__validateOU__body {
  padding-bottom: 100px !important;
  text-align: center;
}

.modal__header {
  width: 550px !important;
  padding: 1rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-left: 10px;
  font-size: 40px !important;
}

.modal__topHeaderValidateOU {
  background-color: lightgrey;
  margin-bottom: 40px;
}

.modal__topHeaderValidateOU > span {
  font-size: 1.5rem !important;
  cursor: pointer !important;
  margin-right: 15px !important;
}

span > h2 {
  font-size: 20px;
  text-align: center;
  padding-top: 10px;
}

b {
  text-align: center;
}

.modal__OU__button {
  width: 200px !important;
  height: 40px;
  margin: 20px auto;
  background-color: var(--sportson-yellow-color);
  border-radius: 5px !important;
  border: none !important;
  cursor: pointer;
}