.ordercentralTitle__container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 0.9;
    text-align: center;
    align-items: center;
}
.ordercentral__container {
    margin-top: 25px;
    background-color: var(--sportson-lightgrey-color);
    padding-left: 25px;
    padding-right: 25px;
}

.ordercentral__tab__container {
    padding-top: 40px;
    padding-bottom: 15px;
}

.ordercentral__tabLink {
    position: relative;
    top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 50px;
    margin-right: 50px;
    color: var(--sportson-darkgrey-color);
    font-weight: bold;
    border: none;
    background: none;
}

.ordercentral__tabLink:hover {
    color: var(--sportson-darkgrey-color);
}

a:hover {
    text-decoration: none !important;
    color: inherit !important;
}

.ordercentral__tabLinkClicked {
    border-bottom: 5px solid var(--sportson-grey-color);
    padding-bottom: 25px;
}

.ordercentral__supportButton {
    position: absolute;
    right: 20px;
    border-radius: 50px;
    color: white;
    background-color: var(--sportson-darkgrey-color);
    width: 180px;
    padding-bottom: 10px;
}

.ordercentral__supportIcon {
    position: relative;
    top: 10px;
    left: -5px;
}

.ordercentral__orderReturnDiv {
    display: flex;
    justify-content: center;
    background-color: var(--sportson-lightgrey-color);
}

.ordercentral__larsson__tabLink {
    margin: 0 30px;
    color: black;
    font-size: 16px;
    font-weight: 600 !important;
    border: none;
    background: none;
}

.ordercentral__larsson__tabLinkClicked {
    border-bottom: 3px solid var(--sportson-yellow-color);
    padding-bottom: 25px;
}

.ordercentral__larsson__div {
    display: flex;
    justify-content: center;
    background-color: var(--sportson-lightgrey-color);
}




