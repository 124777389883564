.body {
  font-family: FSEmericCore, sans-serif;
}

.modal__topHeader > span {
  font-size: 1.5rem !important;
  cursor: pointer !important;
  margin-right: 15px !important;
}

.modal__demo {
  text-align: left;
}

.modal__demo > h2 {
  font-size: 16px;
  text-align: center !important;
}

.modal__demo__input {
  margin: 10px !important;
  width: 50px !important;
}

.modal__demo__button {
  width: 200px !important;
  height: 50px;
  margin: 10px;
  background-color: var(--sportson-yellow-color);
  border-radius: 5px !important;
  border: none !important;
  cursor: pointer;
}

.modal__demo_checkbox {
  margin-right: 10px;
}

.modal__demo__datesDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
}

.modal__demo__timesDiv {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 20px auto;
}

.modal__demo__date {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 40px;
  width: 205px;
  font-size: 16px;
}

.modal__demo__time {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 40px;
  width: 205px;
  font-size: 16px;
}

.modal__demo__dateInputFields {
  border-radius: 10px;
  margin-left: 10px;
  color: grey;
  text-align: center;
}

.modal__demo__timeInputFields {
  position: relative;
  left: -125px;
  border-radius: 10px;
  width: 85px;
  color: grey;
  text-align: center;
  transform: scale(1.25);
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.modal__demo__textFields {
  margin: 0 auto 20px;
}

.modal__demo__buttonDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}