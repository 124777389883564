.test {
  display: flex;
  justify-content: center;
  align-items: center;
}

.radioButton {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  text-align: center;
}
