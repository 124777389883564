.searchOrder {
  position: relative;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--sportson-lightgrey-color);
  flex: 0.9;
}

.searchOrder__formContainer {
  background-color: var(--sportson-lightgrey-color);
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 40px;
}

.searchOrder__inputText {
  width: 350px !important;
  background-color: white;
}

.searchOrder__inputContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
  align-items: center;
  border-top: 1px;
  padding-top: 460px;
}

.searchOrder__inputContainer > input {
  width: 100%;
  height: 50px;
  margin-bottom: 30px;
  margin-left: -2px;
  background-color: white;
}

/* .searchOrder__inputText > .Mui-focused {
    outline: none !important;
    border-color: rgb(255, 187, 0) !important;
    box-shadow: 0 0 10px rgb(255, 187, 0) !important;
  } */

.search__inputContainer > input,
input::-webkit-input-placeholder {
  font-size: 20px;
  line-height: 3;
}

.searchOrder__searchBtn {
  height: 56px;
  width: 90px;
  background-color: var(--sportson-yellow-color);
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: var(--sportson-darkgrey-color);
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
