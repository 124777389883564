.giftcard {
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--sportson-lightgrey-color);
  flex: 0.9;
}

.giftcard__inputText {
  width: 350px !important;
  background-color: white;
}

.giftcard__inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px;
  padding-top: 176px;
  padding-bottom: 92px;
}

.giftcard__inputContainer > input {
  width: 100%;
  height: 50px;
  margin-bottom: 30px;
  margin-left: -2px;
  background-color: white;
}

.giftcard__searchBtn {
  height: 58px;
  width: 90px;
  background-color: var(--sportson-yellow-color);
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: var(--sportson-darkgrey-color);
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.giftcard__captionText {
  position: absolute;
  top: 235px;
  font-size: 11px;
}

/*
@media (max-width: 810px) {
  .card {
    width: 330px !important;
    margin-left: -550px;
  }
  .card__topContainer {
    width: 275px;
  }
  .giftcard__inputContainer {
    width: 265px;
    margin-left: -400px;
  }
  select.card__shopSelector {
    margin-left: 10px;
    width: 120px;
  }
  .card__col,.card__col1 {
    margin-right: 15px;
  }
}
*/