.body {
  font-family: FSEmericCore,sans-serif;
}

.modal__header {
  width: 550px !important;
  padding: 1rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-left: 10px;
}

.modal__topHeader > span {
  font-size: 1.5rem !important;
  cursor: pointer !important;
  margin-right: 15px !important;
}

.modal__editUser__checkboxDiv {
  display: flex;
  align-items: center;
}

.modal__editUser__checkboxDiv > label {
  position: relative;
  top: 5px;
}

.modal__editUser__input {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.modal__editUser__button {
  width: 200px !important;
  height: 40px;
  margin-left: 10px;
  margin-bottom: 20px;
  background-color: var(--sportson-yellow-color);
  border-radius: 5px !important;
  border: none !important;
  cursor: pointer;
}

.modal__editUser__buttonDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modal__editUser__select {
  position: relative;
  left: -10px;
}

#userInactivated {
  position: relative;
  margin-left: 5px;
}
