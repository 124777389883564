.login {
  background-color: #111;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: FSEmericCore, sans-serif;
  justify-content: center;
}

.login__img {
  transform: scale(1.25);
}

.login__imgDiv {
  padding-bottom: 40px;
}

.login__inputContainer {
  display: flex !important;
  flex-direction: column !important;
  width: 475px !important;
}

.MuiTextField-root {
  border-radius: 5px;
}

.login__textfield {
  display: flex;
  flex-direction: column;
  margin: 10px !important;
  background-color: white;
  margin-bottom: 20px !important;
}

.login__button {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-left: 10px !important;
  background-color: var(--sportson-yellow-color) !important;
  color: #111;
  width: 455px;
  height: 50px;
  margin-top: 30px !important;
  font-size: 1.3rem !important;
}
