* {
  margin: 0;

  /* CSS Variables */
  --sportson-yellow-color: #ffcc00;
  --sportson-green-color: #79d977;
  --sportson-red-color: #ff7d61;
  --sportson-lightgrey-color: #f7f7f7;
  --sportson-darkgrey-color: #3f3f3f;
  --sportson-grey-color: #898989;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  src: url(fonts/FSEmeric-Medium.otf);
  font-family: FSEmericMedium;
}

@font-face {
  src: url(fonts/FSEmeric-Light.otf);
  font-family: FSEmericLight;
}

@font-face {
  src: url(fonts/FSEmeric-Core.otf);
  font-family: FSEmericCore;
}

@font-face {
  src: url(fonts/FSEmeric-CoreItalic.otf);
  font-family: FSEmericItalic;
}
