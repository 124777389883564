.modal {
  font-family: FSEmericCore, sans-serif;
  display: block !important;
}

.modal__header {
  width: 550px !important;
  padding: 1rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-left: 50px;
  white-space: pre-wrap;
}

.modal__header > h2 {
  font-size: 16px;
}

.modal__title {
  margin-left: 120px;
  font-weight: 500;
  padding-bottom: 20px;
}
.modal__body {
  text-align: center !important;
}

.modal__topHeader > span {
  font-size: 1.5rem !important;
  cursor: pointer !important;
  margin-right: 15px !important;
}

.modal__button {
  display: inline-block !important;
  width: 200px !important;
  height: 40px;
  margin: 20px auto !important;
  background-color: var(--sportson-yellow-color);
  border-radius: 5px !important;
  border: none !important;
}
