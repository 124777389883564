.hkSupport__toggleBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 100000;
}

.modal__header {
  margin-left: 10px;
  margin-top: 10px;
}

.hkSupport__select {
  height: 55px;
  width: 100%;
  border-color: rgb(196, 196, 196);
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  -webkit-appearance: menulist;
  font-size: 16px;
  color: black;
  padding-left: 10px;
}

.hkSupport__tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-top: 20px;
}

.hkSupport__content {
  display: none;
}

.active-content {
  display: block;
}
