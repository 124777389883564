.modalTitle__button {
  background-color: transparent !important;
  font-size: 11px !important;
  color: var(--sportson-grey-color) !important;
  border: none !important;
}

.modal__fonsterInfo {
  align-items: center;
  margin-top: 10px;
  color: var(--sportson-grey-color);
}

.modal__fonsterInfo > p {
  display: flex;
  font-size: 9px;
  align-items: center;
  margin-left: 10px;
}

.modal__fonsterInfo > p > strong {
  font-size: 16px;
  font-weight: 400;
  color: white;
  background-color: black;
  padding: 5px;
  border-radius: 5px;
}

.modal__fonsterInfo > p > small {
  font-size: 11px;
  border: 1px solid var(--sportson-grey-color);
  color: var(--sportson-grey-color);
  padding: 5px;
  border-radius: 5px;
  margin-left: 20px;
}

.modal.show,
.modal-backdrop.show{
  z-index: 100000 !important;
}

.button__container {
  justify-content: space-between;
  margin-right: 130px !important;
}

.button__open {
  margin-right: 20px;
  background-color: black !important;
}

.button__download {
  background-color: black !important;
}

.table-bordered td {
  border-left: none !important;
  border-right: none !important;
}

.table td {
  padding: 20px !important;
}

.table-bordered th {
  border: none !important;
  padding: 20px !important;
}
