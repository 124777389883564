.invoiceNumbers__invoiceContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: left;
    padding: 5px 35px;
    border-radius: 15px;
}

.invoiceNumbers__invoiceContainer > h5 {
    background-color: var(--sportson-lightgrey-color);
    padding-bottom: 10px;
    margin-bottom: 0 !important;
    font-size: 16px;
    border-bottom: 1px solid var(--sportson-grey-color);
}

.invoiceNumbers__invoiceContainer > h4 {
    font-weight: 600;
    font-size: 16px;
}

.invoiceNumbers__addInvoiceNumberDiv {
    margin-bottom: 5px;
}

.invoiceNumbers__invoiceNumber {
    background-color: lightgrey;
    padding: 5px 15px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top: 1px solid darkgray;
    margin-right: 20px;
    font-size: 16px;
}

.invoiceNumbers__addInvoiceNumberButton {
    position: relative;
    top: 7px;
    width: 45px;
    height: 35px;
    background-color: white;
    border-radius: 5px;
    border: 2px solid black;
    font-size: 11px;
    text-align: left;
    transform: scale(0.85);
}

.invoiceNumbers__deleteInvoiceNumberButton{
    position: relative;
    top: 7px;
    left: 5px;
    width: 45px;
    height: 35px;
    background-color: white;
    border-radius: 5px;
    border: 2px solid black;
    font-size: 11px;
    text-align: left;
    transform: scale(0.85);
}

.invoiceNumbers__editInvoiceNumberButton {
    position: relative;
    top: 7px;
    left: 5px;
    width: 45px;
    height: 35px;
    background-color: limegreen;
    border-radius: 5px;
    border: 2px solid black;
    font-size: 11px;
    text-align: left;
    transform: scale(0.85);
}

.invoiceNumbers__editInvoiceNumber {
    font-size: 18px;
}