.comment__comment_text {
    font-size: 16px;
    margin-bottom: 10px;
    white-space: pre-wrap;
}

.comment__date {
    float: right;
}

.comment__commentDiv {
    word-wrap: break-word;
}

