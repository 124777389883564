.news {
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 0.9;
}

.news__loadingDiv {
    margin-top: 200px;
}

.news__loader {
    border: 16px solid var(--sportson-lightgrey-color);
    border-top: 16px solid var(--sportson-yellow-color);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.news__div {
    margin-top: 570px; /* Top margin for the newsletter */
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}
