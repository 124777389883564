.articleform {
  background-color: var(--sportson-lightgrey-color);
}

.articleForm__inputText {
  width: 350px !important;
  background-color: white;
}

.articleForm__inputContainer {
  display: flex;
  justify-content: center;
  padding-top: 176px;
  align-items: center;
  border-top: 1px;
  padding-bottom: 100px;
}

.articleForm__inputContainer > input {
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
  margin-left: -2px;
  background-color: white;
}

.search__inputContainer > input,
input::-webkit-input-placeholder {
  font-size: 20px;
  line-height: 3;
}

.articleForm__searchBtn {
  height: 58px;
  width: 90px;
  background-color: var(--sportson-yellow-color);
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: var(--sportson-darkgrey-color);
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.articleForm__shopSelector {
  height: 55px;
  width: 200px;
  border-color: rgb(196, 196, 196);
  border-radius: 1px;
}

.articleForm__formContainer {
  background-color: var(--sportson-lightgrey-color);
  width: 100%;
  justify-content: center;
  display: flex;
}

.horizintalLine {
  width: 1200px;
  text-align: center;
  margin-bottom: 30px;
}