.formanscykelFonsterPC {
    width: 100%;
    background-color: white;
    padding: 25px;
}

.formanscykelFonsterPC h4,
.formanscykelFonsterPC h5 {
    font-size: 16px;
}

.formanscykelFonsterPC__table {
    display: table;
    table-layout: fixed;
    width: 100%;
    border-bottom: 1px solid grey;
    padding-top: 5px;
    padding-bottom: 5px;
}

.formanscykelFonsterPC__rowHeader {
    border-bottom: 1px solid grey;
}

.formanscykelFonsterPC__rowColumnsDate {
    width: 200px;
}

.formanscykelFonsterPC__rowColumnsName {
    width: 15%;
}

.formanscykelFonsterPC__rowColumns {
    width: 12%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.formanscykelFonsterPC__rowColumnsHalf {
    width: 6%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.formanscykelFonsterPC__rowGray {
    background-color: var(--sportson-lightgrey-color);
}

.formanscykelFonsterPC__row {
    height: 40px;
}

.formanscykelFonsterPC__dateRange {
    background-color: black;
    color: white;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 15px;
    font-weight: bold;
}

.formanscykelFonsterPC__month {
    color: black;
    font-size: 16px;
}

.formanscykelFonsterPC__orderDiv {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--sportson-lightgrey-color);
}

.formanscykelFonsterPC__searchResultsHeader {
    float: left;
    margin-bottom: 20px;
}

.formanscykelFonsterPC__pageButton {
    border-radius: 10px;
    font-size: 20px;
    width: 50px;
    height: 50px;
    background-color: var(--sportson-lightgrey-color);
    border: 2px solid var(--sportson-grey-color);
    margin: 30px 5px 5px;
}

.formanscykelFonsterPC__currentPage {
    text-decoration: underline;
}

.formanscykelFonsterPC__loadingDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    margin-bottom: 200px;
}
.formanscykelFonsterPC__loader {
    border: 16px solid var(--sportson-lightgrey-color);
    border-top: 16px solid var(--sportson-yellow-color);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.formanscykelFonsterPC__pageButtonsDiv {
    display: flex;
    justify-content: center;
}

.formanscykelFonsterPC__sortButton {
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;
    font-size: 16px;
}