.sidebarRow {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 25px;
  height: 50px !important;
}

.sidebarRow__wrapper {
  display: flex;
}

.sidebarRow__icon {
  font-size: 14px !important;
}

.sidebarRow--selected,
.sidebarRow.selected{
  background-color: var(--sportson-darkgrey-color);
}

.sidebarRow--selected > .sidebarRow__icon,
.sidebarRow:hover > .sidebarRow__icon,
.sidebarRow.selected > .sidebarRow__icon {
  color: var(--sportson-yellow-color);
}

.sidebarRow--selected > .sidebarRow__title,
.sidebarRow:hover > .sidebarRow__title,
.sidebarRow.selected > .sidebarRow__title {
  font-weight: bold;
}

.sidebarRow__title {
  flex: 1;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  text-align: left;
  line-height: 1.5;
  padding-left: 10px;
  padding-right: 10px;
}

.sidebarRow__indent {
  margin-left: 15px;
}

.sidebarRow:hover {
  background-color: var(--sportson-darkgrey-color);
  cursor: pointer;
  transition: color 100ms ease-out;
}