.commentCompanyOrWindow__comment_text {
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    margin-left: 10px;
    margin-bottom: 10px;
    white-space: pre-wrap;
    text-align: left;
    width: 822px;
    word-wrap: break-word;
}

.commentCompanyOrWindow__date {
    float: right;
    margin-right: 5px;
    margin-top: 5px;
}

