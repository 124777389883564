.editProductRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.editProductRow__container {
  display: flex;
  background-color: white;
  width: 1500px;
  height: 100px;
  align-items: center;
  margin-top: 15px;
}

.editProductRow__infoContainer {
  display: flex;
  box-shadow: 0 0 0 0 rgba(0, 0, 0.1, 0.1);
  height: 200px;
  /* padding-right: 100px;
  width: 330px; */
}

.editProductRow__img {
  max-width: 150px;
  min-width: 150px;
  transform: scale(0.7);
  object-fit: contain;
  padding-left: 15px;
  padding-right: 30px;
}

.editProductRow__bikeInfo {
  display: flex;
  flex-direction: column;
  flex: 0.1;
  margin-left: 20px;
  justify-content: center;
  position: relative;
  top: -20px;
}
.editProductRow__isDiv {
  display: flex;
  flex-direction: row;
  width: 200px;
}

.editProductRow__supplierTag > h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
}

.editProductRow__itemIsedited {
  border: 2px solid black;
  border-radius: 24px;
  width: 90px;
  text-align: center;
  background-color: #E1BE6A;
  padding: 1px 5px 1px 5px;
  margin-right: 5px;
}

.editProductRow__itemIsCustom {
  border: 2px solid black;
  border-radius: 24px;
  width: 90px;
  text-align: center;
  background-color: #40B0A6;
  padding: 1px 5px 1px 5px;
  margin-right: 5px;
}

.editProductRow__itemIsDeleted {
  border: 2px solid black;
  border-radius: 24px;
  width: 90px;
  text-align: center;
  color: white;
  background-color: darkred;
  padding: 1px 5px 1px 5px;
  margin-right: 5px;
}

.editProductRow__bikeInfo > .editProductRow__supplierTag > h3 {
  color: var(--sportson-grey-color);
  font-weight: 400;
  padding-top: 30px;
  font-size: 16px;
}

.editProductRow__bikeInfo > h1 {
  font-size: 16px;
  width: 230px;
  text-align: left;
  font-weight: 600;
}

.editProductRow__bikeInfo > span {
  color: var(--sportson-grey-color);
  font-size: 16px;
}

.editProductRow__secondContainer {
  display: flex;
  align-items: center;
  flex: 0.6;
}

.editProductRow__artNr {
  position: absolute;
  margin-left: 30px;
  font-weight: 600;
  font-size: 16px;
}

.editProductRow__barcode {
  position: absolute;
  margin-left: 200px;
  font-weight: 600;
  font-size: 16px;
}

.editProductRow__thirdContainer {
  display: flex;
  align-items: center;
  flex: 0.19;
}

.editProductRow__grossPrice {
  position: absolute;
  font-size: 18px;
  width: 100px;
  text-align: right;
}

.editProductRow__quantity {
  position: relative;
  font-size: 18px;
  margin-left: 20px;
  width: 70px;
  text-align: right;
}

.editProductRow__fourthContainer,
.editProductRow__fifthContainer,
.editProductRow__sixthContainer,
.editProductRow__seventhContainer {
  display: flex;
  flex-direction: column;
  flex: 0.20;
}

.editProductRow__seventhContainer {
  position: relative;
  top: -10px;
}

.editProductRow__errandAndFrameNumberInputs {
  width: 250px;
  height: 30px;
  font-size: 18px;
}

/* .editProductRow__comment {
  display: flex;
  position: absolute;
  margin-left: 580px;
  margin-top: 140px;
} */

.editProductRow__commentBtn {
  display: flex;
  position: absolute;
  margin-left: -240px;
  margin-top: 45px;
  background-color: rgb(253, 251, 251);
  border-radius: 30px;
  padding: 5px 15px 5px 15px;
  align-items: center;
  border: 1px solid var(--sportson-lightgrey-color);
  z-index: 1;
  width: 150px;
  height: 30px;
  font-size: 10px;
  cursor: pointer;
}

.editProductRow__commentBtn:focus {
  outline: var(--sportson-darkgrey-color);
}

.editProductRow__commentIcon {
  color: var(--sportson-grey-color) !important;
  width: 20px !important;
  padding-right: 5px;
}

.editProductRow__checkbox {
  margin: -50px -50px -50px 240px;
  font-size: 8px;
}

.editProductRow__optionBtnContainer {
  display: flex;
  position: absolute;
  padding-left: 280px;
}

.editProductRow__receiveBtn {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  /* border-right: none; */
  background-color: rgb(253, 251, 251);
  height: 25px;
  width: 60px;
  border: 1px solid var(--sportson-grey-color);
  font-size: 10px;
  cursor: pointer;
}

.editProductRow__receiveBtn:focus,
.editProductRow__missingBtn:focus {
  outline: var(--sportson-grey-color);
}

.editProductRow__missingBtn {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgb(253, 251, 251);
  height: 25px;
  width: 60px;
  border: 1px solid var(--sportson-grey-color);
  font-size: 10px;
  cursor: pointer;
}

.editProductRow__discountInputs {
  width: 75px;
  font-size: 18px;
  margin-left: 10px;
  color: black;
  text-align: right;
}

.editProductRow__fillColumn {
  width: 250px;
  height: 30px;
}

@media only screen and (max-width: 1030px) {
  .editProductRow__img {
    margin-left: 20px;
    width: 130px;
  }
}
