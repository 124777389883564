@media print {
  .kvittens__header {
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
  }

  .kvittens__logo {
    width: 200px;
    object-fit: contain;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .kvittens__butik {
    padding-top: 30px;
    font-weight: 400;
  }

  .kvittens__title {
    padding-right: 50px;
    text-align: right;
    line-height: 1.4;
    font-weight: 200;
    letter-spacing: 1.5px;
    padding-top: 25px;
  }

  .kvittens__title > h1 {
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  .kvittens__subHeader {
    display: flex;
    padding-left: 50px;
  }

  .kvittens__subHeader > h3 {
    margin-top: 40px;
  }

  .kvittens__subHeader > h4 {
    padding-top: 20px;
    padding-left: 30px;
    padding-bottom: 30px;
    font-weight: 400;
  }

  .kvittens__subHeader > h3 {
    padding-top: 30px;
    font-weight: 500;
  }

  .kvittens__orderContainer {
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    line-height: 1.6;
  }

  .kvittens__orderNr {
    padding-left: 40px;
  }

  .kvittens__orderNr > span,
  .kvittens__datum > span {
    color: var(--sportson-grey-color);
    font-size: 12px;
  }

  .kvittens__orderNr > h4,
  .kvittens__datum > h4 {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 1.6px;
  }

  .kvittens__signatureContainer {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 70px;
    padding-left: 40px;
  }

  .kvittens__employee {
    border-top: 1px solid black;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    font-size: 16px;
    font-weight: 300;
    width: 500px;
  }

  .kvittens__customer {
    border-top: 1px solid black;
    margin-left: 100px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    font-size: 14px;
    font-weight: 300;
  }

  .kvittens__productDetails {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .kvittens__tableHeader {
    font-weight: 500;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 14px;
  }

  .kvittens__table2 {
    padding-left: 390px;
    text-align: right;
  }

  .kvittens_partNo,
  .kvittens__product {
    font-size: 12px;
  }
}
