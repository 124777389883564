.portal__input {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.fileUpload {
  margin-top: 20px;
  display: none;
}
.fileInput {
  position: relative;
  top: -40px;
  left: 10px;
}
input[type=file]{
  color:transparent;
}
.removeFileButton {
  border: none;
  background: none;
  color: var(--sportson-red-color);
}
