.butiksservice {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}

.butiksservice__title > h1 {
  margin-left: 30px;
  font-size: 30px;
  font-weight: 450;
  margin-bottom: 35px;
  margin-top: 40px;
  font-family: FSEmericMedium, sans-serif;
}

.butiksservice__headerNav {
  margin-top: 460px;
  background-color: var(--sportson-lightgrey-color) !important;
  box-shadow: none !important;
  color: black !important;
  display: flex;
  text-transform: none !important;
  width: 100% !important;
}

.butiksservice__navOption {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-right: -20px !important;
  outline: none !important;
  font-family: FSEmericCore, sans-serif !important;
  text-transform: uppercase !important;
}
