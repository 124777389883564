.sidebar {
  position: fixed;
  background-color: #111;
  color: #ffffff;
  height: 100%;
  z-index: 10;
  font-family: FSEmericCore, sans-serif;
  width: 250px;
  margin-top: 50px;
  padding-top: 68px;
  box-sizing: border-box;
  transition: width 0.2s ease-in;
  padding-left: 15px;
  overflow-y: scroll;
}

.sidebar.inactive {
  width: 100px;
  padding-left: 25px;
}

.sidebar.inactive .sidebar__items {
  width: 80px;
}

.sidebar__topSection {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  background-color: #111;
  z-index: 10;
  height: 65px;
  width: 100%;
  padding-left: 7px;
}

.sidebar__input {
  display: none; /* Hide the search bar for now */
  align-items: center;
  background-color: white;
  width: 150px;
  height: 25px;
  padding: 3px 40px 3px 3px;
  border-radius: 40px;
  z-index: 1000;
  margin-left: 7px;
}

.sidebar__input > input {
  flex: 1;
  border: none;
  background-color: transparent;
  font-family: FSEmericLight, sans-serif;
  font-size: 16px !important;
  height: 30px;
  margin-left: 6px;
  width: 50px !important;
  outline-width: 0;
}

.sidebar__input > input::placeholder {
  font-size: 16px;
}

.sidebar__searchIcon {
  position: absolute;
  margin-left: 120px !important;
  color: rgb(127, 127, 127) !important;
}

.sidebar__avatar {
  margin: 10px 8px 10px 10px;
  border: 2px solid rgb(107, 107, 107);
}

.sidebar__link,
.sidebar__link:hover {
  color: white;
  text-decoration: none;
}

.sidebar__logo {
  object-fit: contain;
  justify-content: center;
  align-items: center;
  width: 200px;
  /* padding: 15px; */
  padding-left: 10px;
  padding-top: 25px;
  padding-bottom: 20px;
}

.sidebar__name {
  color: var(--sportson-yellow-color);
}

.sidebar__section2 {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}

.icon__wrapper {
  height: 40px;
  width: 40px;
  display: block;
  background-color: white;
  border-radius: 50%;
  text-align: center;
  margin-right: 30px;
  margin-left: 30px;
}

.icon__wrapper:hover {
  background-color: var(--sportson-yellow-color);
}

.logoutIcon {
  color: #000 !important;
  font-size: 25px !important;
  margin-top: 7px;
}
.portalIcon {
  margin-left: 4px;
}

.sidebar__divider {
  height: 0.5px;
  border: 0;
  background-color: var(--sportson-lightgrey-color);
  margin: 5px 20px;
}

.sidebar__menuBtn {
  color: var(--sportson-yellow-color) !important;
  position: absolute;
  right: 0;
  margin-right: 10px;
  font-size: 2rem;
  width: 35px !important;
  height: 35px;
  background-color: rgb(32, 32, 32) !important;
  border-radius: 5px;
  border: 1px solid transparent;
  transform: translateY(-70%);
  cursor: pointer;
}

.MuiSvgIcon-root {
  vertical-align: 4px !important;
}

.sidebar.inactive .sidebar__menuBtn {
  right: 10px;
  top: 60px;
}

.sidebar {
  margin: 30px -25px;
}

ul > li {
  list-style-type: none;
  margin-bottom: 10px;
}

.sidebar .sidebar__item {
  color: white;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 25px;
}

.sidebar .sidebar__item .sidebar__menuIcon {
  display: inline-block;
  text-align: center;
}

.sidebar .sidebar__item > span {
  position: absolute;
  display: inline-block;
  left: 60px;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.sidebar.inactive .sidebar__item > span {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.sidebar .sidebar__subMenu {
  margin-left: 20px;
  border-left: 1px solid rgb(202, 200, 200);
  box-sizing: border-box;
  padding-left: 25px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in;
  margin-top: 15px;
}

.sidebar .sidebar__subMenu.active {
  max-height: 200px;
}

.sidebar .sidebar__subMenu > li > span {
  display: block;
  margin: 5px 0;
  font-size: 14px;
  font-weight: 600;
}

.sidebar > .sidebar__subMenu > li > span.active {
  background: red;
  display: block;
  margin: 5px;
  font-size: 14px;
  font-weight: 600;
  box-sizing: border-box;
}

.sidebar .sidebar__menuFooter .sidebar__hkSupportBtn {
  color: white;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 25px;
}

.sidebar .sidebar__hkSupportBtn {
  margin-left: 15px !important;
  display: flex;
  color: var(--sportson-yellow-color) !important;
  font-weight: 900 !important;
  text-align: left;
  background-color: transparent;
  border: none;
  margin-top: 25px;
  cursor: pointer;
}

.sidebar .sidebar__hkSupportBtn .hk__btnText {
  position: absolute;
  display: inline-block;
  font-size: 14px;
  left: 50px;
  opacity: 1;
  transition: opacity 0.2s ease-in;
  line-height: 30px;
  margin-left: 22px;
}

.sidebar.inactive .sidebar__hkSupportBtn .hk__btnText {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.sidebar__hkIcon,
.sidebar__hkIcon.portalIcon,
.sidebar__menuIcon {
  display: inline-block;
  width: 25px !important;
  height: 25px !important;
  text-align: center;
  font-size: 14px !important;
}

.sidebar__adminTitle {
  text-align: center;
  margin-top: 20px;
}

.sidebar__adminHr {
  border-bottom: 0.5px solid var(--sportson-lightgrey-color);
  margin-right: 20px;
  margin-left: 20px;
}

 @media (max-width: 810px) {
   .sidebar {
     width: 100px;
     padding-left: 25px;
     margin: 30px -25px;
   }
   .sidebar .sidebar__items {
     width: 80px;
   }
   .sidebar .sidebar__item > span,
   .sidebar .sidebar__hkSupportBtn .hk__btnText {
     opacity: 0;
     width: 0;
     height: 0;
     overflow: hidden;
   }
   .sidebar__menuBtn {
     display: none;
   }
}
