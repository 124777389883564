.productRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.productRow__container {
  display: flex;
  background-color: white;
  width: 1500px;
  height: 175px;
  align-items: center;
}

.productRow__infoContainer {
  display: flex;
  box-shadow: 0 0 0 0 rgba(0, 0, 0.1, 0.1);
  height: 200px;
  /* padding-right: 100px;
  width: 330px; */
}

.productRow__img {
  margin-top: 10px;
  max-width: 150px;
  min-width: 150px;
  transform: scale(0.9);
  object-fit: contain;
  padding-left: 15px;
  padding-right: 30px;
}

.productRow__bikeInfo {
  display: flex;
  flex-direction: column;
  flex: 0.1;
  margin-left: 20px;
  justify-content: center;
  position: relative;
  top: -20px;
}
.productRow__isDiv {
  display: flex;
  flex-direction: column;
}

.productRow__supplierTag > h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
}

.productRow__itemIsEdited {
  border: 2px solid black;
  border-radius: 24px;
  width: 110px;
  text-align: center;
  background-color: #E1BE6A;
  padding: 1px 15px 1px 15px;
  margin-right: 5px;
}

.productRow__itemIsCustom {
  border: 2px solid black;
  border-radius: 24px;
  width: 110px;
  text-align: center;
  background-color: #40B0A6;
  padding: 1px 15px 1px 15px;
  margin-right: 5px;
}

.productRow__itemIsDeleted {
  border: 2px solid black;
  border-radius: 24px;
  width: 110px;
  text-align: center;
  color: white;
  background-color: darkred;
  padding: 1px 15px 1px 15px;
  margin-right: 5px;
}

.productRow__bikeInfo > .productRow__supplierTag > h3 {
  color: var(--sportson-grey-color);
  font-weight: 400;
  padding-top: 30px;
  font-size: 16px;
}

/* .productRow__supplierTag > span {
  padding-right: 20px;
} */

.productRow__bikeInfo > h1 {
  font-size: 16px;
  width: 230px;
  text-align: left;
  font-weight: 600;
}

.productRow__bikeInfo > span {
  color: var(--sportson-grey-color);
  font-size: 16px;
}

.productRow__secondContainer {
  display: flex;
  align-items: center;
  flex: 0.6;
}

.productRow__artNr {
  position: absolute;
  margin-left: 30px;
  font-weight: 600;
  font-size: 16px;
}

.productRow__barcode {
  position: absolute;
  margin-left: 240px;
  font-weight: 600;
  font-size: 16px;
}

.productRoww__momsText {
  position: absolute;
  margin-top: -50px;
  margin-left: 625px;
  color: var(--sportson-grey-color);
}

.productRow__thirdContainer {
  display: flex;
  align-items: center;
  flex: 0.32;
}

.productRow__grossPrice,
.productRow__quantity {
  position: absolute;
  font-weight: 600;
  font-size: 16px;
}

.productRow__fourthContainer {
  display: flex;
  align-items: center;
  flex: 0.19;
}

.productRow__fifthContainer,
.productRow__sixthContainer,
.productRow__seventhContainer {
  display: flex;
  flex-direction: column;
  flex: 0.19;
}

/* .productRow__comment {
  display: flex;
  position: absolute;
  margin-left: 580px;
  margin-top: 140px;
} */

.productRow__commentBtn {
  display: flex;
  position: absolute;
  margin-left: -240px;
  margin-top: 45px;
  background-color: rgb(253, 251, 251);
  border-radius: 30px;
  padding: 5px 15px 5px 15px;
  align-items: center;
  border: 1px solid var(--sportson-lightgrey-color);
  z-index: 1;
  width: 150px;
  height: 30px;
  font-size: 10px;
  cursor: pointer;
}

.productRow__commentBtn:focus {
  outline: var(--sportson-darkgrey-color);
}

.productRow__commentIcon {
  color: var(--sportson-grey-color) !important;
  width: 20px !important;
  padding-right: 5px;
}

.productRow__checkbox {
  margin: -50px -50px -50px 240px;
  font-size: 8px;
}

.productRow__optionBtnContainer {
  display: flex;
  position: absolute;
  padding-left: 280px;
}

.productRow__receiveBtn {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  /* border-right: none; */
  background-color: rgb(253, 251, 251);
  height: 25px;
  width: 60px;
  border: 1px solid var(--sportson-grey-color);
  font-size: 10px;
  cursor: pointer;
}

.productRow__missingBtn {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgb(253, 251, 251);
  height: 25px;
  width: 60px;
  border: 1px solid var(--sportson-grey-color);
  font-size: 10px;
  cursor: pointer;
}

.productRow__receiveBtn:focus,
.productRow__missingBtn:focus {
  outline: var(--sportson-grey-color);
}

@media only screen and (max-width: 1030px) {
  .productRow__img {
    margin-left: 20px;
    width: 130px;
  }
}
/* @media only screen and (max-width: 1800px) {
  .productRow {
    margin: 0px;
  }

  .productRow__container {
    border-radius: 0px;
  }

  .productRow__infoContainer {
    padding-right: 20px;
  }

  .productRow__img {
    width: 120px;
  }

  .productRow__bikeInfo > h1 {
    font-size: 24px;
    padding-bottom: 20px;
    width: 250px;
  }

  .productRow__artNr {
    margin-left: 60px;
  }

  .productRow__price {
    margin-left: 330px;
  }

  .productRoww__momsText {
    margin-left: 762px;
  }

  .productRow__thirdContainer {
    margin-left: 460px;
  }

  .productRow__checkbox {
    margin: auto;
    margin-left: 480px;
  }

  .productRowShopView__optionBtnContainer {
    padding-left: 775px;
  }
}

@media only screen and (max-width: 1700px) {
  .productRow__artNr {
    margin-left: 60px;
  }

  .productRow__price {
    margin-left: 330px;
  }

  .productRow__commentBtn {
    margin-left: -410px;
  }

  .productRow__thirdContainer {
    margin-left: 460px;
  }

  .productRow__checkbox {
    margin: auto;
    margin-left: 300px;
  }

  .productRow__commentBtn {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 1750px) {
  .productRow__optionBtnContainer {
    padding-left: 960px;
  }
}

@media only screen and (max-width: 1700px) {
  .productRow__optionBtnContainer {
    padding-left: 900px;
  }

  .productRow__commentBtn {
    margin-left: -680px;
  }
}

@media only screen and (max-width: 1600px) {
  .productRow__artNr {
    margin-left: 30px;
  }

  .productRow__price {
    margin-left: 300px;
  }

  .productRow__thirdContainer {
    margin-left: 460px;
  }

  .productRow__checkbox {
    margin: auto;
    margin-left: 200px;
  }

  .productRow__commentBtn {
    margin-left: -610px;
  }

  .productRow__checkbox {
    margin: auto;
    margin-left: 300px;
  }

  .productRow__optionBtnContainer {
    padding-left: 820px;
  }
} */
