.header {
  position: fixed;
  top: 0;
  z-index: 100001;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 65px;
  width: 350px;
}

.header__section1 {
  position: absolute;
  z-index: 100001;
}

.header__title {
  font-size: 22px;
  font-family: FSEmericCore, sans-serif;
  color: white;
  font-weight: 200;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
}

.header__bannerContainer {
  margin-top: 350px;
}

/*
@media (max-width: 810px) {
  .header {
    left: 0;
    margin-left: 200px;
    height: 50px;
    width: 50px;
  }
  .header__title {
    font-size: 11px;
  }
}
*/