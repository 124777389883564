.orderForm {
  display: flex !important;
  flex-direction: column !important;
  text-align: left;
  margin-bottom: 20px;
}

.orderForm__Container {
  display: flex;
  justify-content: center;
  background-color: var(--sportson-lightgrey-color);
  height: 100%;
  max-width: 1800px;
  width: 1500px;
}

.orderForm__ContainerMarkedForDeletion {
  background-color: red !important;
}

.orderForm__header {
  width: 100%;
}

.orderForm__latestChanged {
  margin-bottom: 25px;
}

.orderForm__title > h1,
.orderForm__InfoContainer > h1 {
  font-weight: 600;
  text-align: left;
  font-size: 16px;
  color: white;
}

.orderForm__orderType {
  color: white;
  font-size: 16px;
}

.orderForm__orderType > span {
  color: var(--sportson-yellow-color);
}

.orderForm__subHeader {
  display: flex;
  justify-content: space-between;
  padding: 5px 35px 0 35px;
  text-align: left;
  background-color: white;
}

.orderForm__customer {
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;
}

.orderForm__return {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 10px 35px 0 35px;
}

.orderForm__checkboxDiv {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}

.orderForm__checkboxDiv > p {
  font-weight: bold;
}

.orderForm__checkbox {
  position: relative;
  top: 3px;
  margin-left: 10px;
}

.orderForm__shop {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  padding: 5px 25px;
}

.orderForm__assignedShop {
  flex: 1;
  background-color: var(--sportson-lightgrey-color);
  margin: 5px 10px 0 10px;
  border-radius: 10px;
}

.orderForm__assignedShop > h6 {
  position: relative;
  top: -10px;
  left: 20px;
  color: var(--sportson-grey-color);
}

.orderForm__assignedShop > h5 {
  position: relative;
  top: -10px;
  left: 20px;
  font-size: 16px;
}

.orderForm__expandButton {
  border: none;
  background: none;
  outline: none;
  transform: scale(2);
  padding-top: 5px;
}

.orderForm__expandButton:focus {
  outline: none;
}

.orderForm__customerComments {
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding: 5px 35px;
}

.orderForm__sportsonComments {
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding: 5px 35px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.orderForm__commentIcon {
  color: var(--sportson-yellow-color);
  position: relative;
  top: 10px;
  left: 10px;
}

.orderForm__comments {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: left;
  border-radius: 10px;
}

.orderForm__customerComment {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 5px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-left: 40px;
  margin-bottom: 10px;
  width: 95%;
  background-color: #ebf8f9;
}

.orderForm__sportsonComment {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 5px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-left: 40px;
  margin-bottom: 10px;
  width: 95%;
  background-color: lightyellow;
}

.orderForm__comment > h5 {
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}

.orderForm__comment > h6 {
  color: var(--sportson-darkgrey-color);
}

.orderForm__customerCard {
  display: flex;
  flex-direction: column;
  background-color: var(--sportson-lightgrey-color);
  flex: 0.95;
  border-radius: 10px;
  padding: 5px 15px 0 15px;
  margin-bottom: 5px;
}

.orderForm__customerCardRow {
  display: flex;
}

.orderForm__customerCardRowItem {
  flex: 0.25;
}

.orderForm__customerSocialSecNumVerified {
  background-color: lightgreen;
  padding: 5px 10px;
  border-radius: 5px;
}

.orderForm__customerSocialSecNumNotVerified {
  background-color: lightcoral;
  padding: 5px 10px;
  border-radius: 5px;
}

.orderForm__orderContainer {
  display: flex;
  justify-content: space-between;
}

.orderForm__datum {
  padding-left: 50px;
  width: 160px;
}

.orderForm__orderNr {
  padding-left: 0 !important;
}

.orderForm__orderNr > h3,
.orderForm__datum > h3,
.orderForm__status > h3 {
  padding-top: 10px;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 600;
}

.orderForm__datum > input {
  display: block;
  margin-top: 10px;
  margin-bottom: 8px;
  height: 28.8px;
}

.orderForm__status {
  padding-left: 50px;
}

.orderForm__deliveryContainer {
  margin-left: 20px;
  margin-right: 20px;
}

.orderForm__latestChanged > span,
.orderForm__datum h4,
.orderForm__orderNr > span,
.orderForm__datum > span,
.orderForm__deliveryContainer > span,
.orderForm__companyContainer > span,
.orderForm__vy > span,
.orderForm__customerCardRowItem > span,
.orderForm__status > span,
.orderForm__sumsContainerDiv > h4,
.orderForm__titelsKund > h5 {
  font-size: 16px;
  color: var(--sportson-grey-color);
}

.orderForm__deliveryContainer > h3,
.orderForm__companyContainer > h3 {
  font-size: 16px;
  padding-top: 10px;
  font-weight: 600;
}

.orderForm__title {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  background-color: black;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.orderForm__InfoContainer {
  display: flex;
  justify-content: space-between;
  padding: 25px 25px 5px 25px;
  background-color: black;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.orderForm__buttonWrap {
  display: flex;
}

.orderForm__horizontalLine {
  height: 2px;
  width: 1470px;
  margin: 0 auto;
  background-color: lightgrey;
}

.orderForm__wholeWidthHorizontalLine {
  height: 2px;
  width: 100%;
  background-color: lightgrey;
}

.orderForm__InfoContainer > h3 {
  font-size: 16px;
  font-weight: 500;
}

.orderForm__sumsContainer {
  display: flex;
  justify-content: left;
  background-color: white;
  padding: 10px 35px;
}

.orderForm__sumsContainerDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--sportson-lightgrey-color);
  border-radius: 10px;
  width: 200px;
  height: 55px;
  margin-right: 25px;
}

.orderForm__sumsContainerDiv > h2 {
  font-weight: 600;
  font-size: 16px;
}

.orderForm__vy {
  display: flex;
  align-items: center;
}

.orderForm__toggleBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.orderForm__tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--sportson-yellow-color);
  width: 70px;
  height: 20px;
  padding: 5px;
  border: 1px solid var(--sportson-grey-color);
}

.orderForm__content {
  display: none;
  background-color: white;
}

.active-content {
  display: block;
}

.orderForm__titelsKund {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 0 300px 25px 220px;
  background-color: white !important;
}

.orderForm__picProductKund {
  position: absolute;
  font-size: 16px;
  margin-left: -200px;
}

.orderForm__productNameKund {
  position: absolute;
  font-size: 16px;
  margin-left: -50px;
}

.orderForm__artNrTextKund {
  position: absolute;
  margin-left: 210px;
  font-size: 18px;
}

.orderForm__barcodeKund {
  position: absolute;
  margin-left: 380px;
  font-size: 16px;
}

.orderForm__grossPriceKund {
  position: absolute;
  margin-left: 545px;
  font-size: 16px;
}
.orderForm__amountKund {
  position: absolute;
  margin-left: 685px;
  font-size: 16px;
}

.orderForm__discountPercentage {
  position: absolute;
  margin-left: 795px;
  font-size: 18px;
}
.orderForm__discountAmount {
  position: absolute;
  margin-left: 915px;
  font-size: 18px;
}

.orderForm__idKund {
  position: absolute;
  margin-left: 1160px;
  font-size: 16px;
}

.orderForm__titelsButik {
  display: flex;
  justify-content: space-between;
  padding-left: 150px;
  padding-right: 300px;
  padding-bottom: 20px;
  color: var(--sportson-grey-color);
  text-align: left;
  font-size: 13px;
}

.orderForm__productTextButik {
  position: absolute;
  font-size: 14px;
  margin-left: -30px;
}

.orderForm__artNrTextButik {
  position: absolute;
  margin-left: 120px;
  font-size: 14px;
}

.orderForm__priceTextButik {
  position: absolute;
  margin-left: 245px;
  font-size: 14px;
}

.orderForm__shopPriceTextButik {
  position: absolute;
  margin-left: 377px;
  font-size: 14px;
}

.orderForm__newPriceTextButik {
  position: absolute;
  margin-left: 485px;
  font-size: 14px;
}

.orderForm__tgTextButik {
  position: absolute;
  margin-left: 600px;
  font-size: 14px;
}

.orderForm__sendBtn {
  border: 1px solid black;
  margin-left: 700px;
  margin-top: 40px;
  margin-bottom: 60px;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
}

.orderForm__icons {
  position: relative;
  top: 8px;
  margin-left: 10px;
}

.orderForm__failedRulesContainer > h5,
.orderForm__attention > h3,
.orderForm__customerCardRowItem > h3,
.orderForm__customerComments > h5,
.orderForm__sportsonComments > h5 {
  font-size: 16px;
}

.orderForm__shop > h4,
.orderForm__failedRulesContainer > h4 {
  font-weight: 600;
  margin-left: 20px;
  font-size: 16px;
}

.orderForm__failedRulesContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 5px;
  border-radius: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: red;
}

.orderForm__failedRulesContainer > h5 {
  background-color: var(--sportson-lightgrey-color);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  margin-left: 15px;
  border-radius: 5px;
  margin-bottom: 0 !important;
}

.orderForm__button {
  float: right;
  width: 100px;
  height: 50px;
  background-color: white;
  border-radius: 10px;
  border: 2px solid black;
  font-size: 16px;
  margin-right: 5px;
}

.orderForm__saveButton {
  float: right;
  width: 100px;
  height: 50px;
  background-color: limegreen;
  border-radius: 10px;
  border: 2px solid black;
  font-size: 16px;
  margin-right: 5px;
}

.orderForm__deleteButton {
  float: right;
  width: 100px;
  height: 50px;
  background-color: red;
  border-radius: 10px;
  border: 2px solid black;
  font-size: 16px;
  margin-right: 5px;
}

.orderForm__floatLeft {
  float: left !important;
}

.orderForm__editButton {
  position: relative;
  bottom: 10px;
  left: 25px;
  margin-right: 25px;
  width: 200px;
  height: 35px;
  background-color: white;
  border-radius: 5px;
  border: 2px solid black;
  font-size: 14px;
  text-align: left;
}

.orderForm__restoreButton {
  position: relative;
  top: -5px;
  left: -150px;
  text-align: center;
  font-weight: bold;
  margin: 0 auto;
}

.orderForm__addCommentButton {
  margin-left: 10px;
}

.orderForm__deleteOrderRowButton {
  position: relative;
  top: -1px;
  width: 45px !important;
}

.orderForm__editOrderDateButton {
  width: 45px;
  height: 35px;
  background-color: white;
  border-radius: 5px;
  border: 2px solid black;
  font-size: 11px;
  text-align: left;
}

.orderForm__undoDeletedOrderRowButton {
  position: relative;
  left: 10px;
  top: -1px;
  width: 45px;
  height: 35px;
  border-radius: 5px;
  border: 2px solid black;
  font-size: 11px;
}

.orderForm__editOrderRowButton {
  position: relative;
  left: -3px;
  top: -1px;
  float: right;
  width: 45px;
  height: 35px;
  background-color: limegreen;
  border-radius: 5px;
  border: 2px solid black;
  font-size: 11px;
}

.orderForm__editCustomerButton {
  position: relative;
  left: 95%;
  width: 45px;
  height: 35px;
  background-color: white;
  border-radius: 5px;
  border: 2px solid black;
  font-size: 14px;
  text-align: left;
  margin-bottom: 10px;
}

.orderForm__connectOrderToBenefitWindowButton {
  background-color: var(--sportson-yellow-color);
  border-radius: 5px;
  border: 2px solid black;
  font-size: 16px;
}

.orderForm__iconAndText {
  position: relative;
  top: -5px;
  left: -5px;
}

.orderForm__buttonGDPR {
  float: right;
  width: 150px;
  height: 50px;
  background-color: white;
  border-radius: 10px;
  border: 2px solid black;
  font-size: 16px;
  margin-right: 5px;
}

.orderForm__hidden {
  display: none !important;
}

.orderForm__attention {
  background-color: red;
  padding: 0 15px;
  border-radius: 5px;
  margin: 0 5px;
  font-size: 16px;
  height: 75px;
}

.orderForm__commentButton {
  width: 35px;
  height: 30px;
  margin-right: 5px;
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid black;
  font-size: 14px;
  text-align: center;
}

.orderForm__inputTypeDate {
  width: 95px;
  border-radius: 5px;
  margin-right: 5px;
  text-align: center;
}

.orderForm__ignoreRulesDiv {
  display: flex;
  justify-content: right;
  margin-top: 10px;
}

.orderForm__checkboxText {
  padding-left: 10px;
}

.orderForm__checkboxTextBackgroundYellow {
  background-color: yellow;
  border-radius: 5px;
  padding-right: 5px;
}

.orderForm__rejectedBG {
  background-color: red !important;
  border-radius: 5px;
  padding: 8px;
}

.orderForm__rejectedBGStepLabel .MuiStepLabel-label {
  color: white !important;
  font-weight: 500 !important;
}

.orderForm__filterSearchSelect {
  position: relative;
  left: -20px;
  width: 225px !important;
  margin: 0 !important
}

@media screen and (max-width: 1700px) {
  .orderForm__Container {
    transform: scale(0.75, 0.9);
  }
}

@media only screen and (max-width: 1030px) {
  .orderForm__title,
  .orderForm__subHeader,
  .orderForm__InfoContainer > h3 {
    margin-left: 40px;
  }

  .orderForm__buttonWrap,
  .orderForm__companyContainer,
  .orderForm__vy {
    margin-right: 40px;
  }

  .orderForm__tgTextButik {
    margin-left: 775px;
  }
}
