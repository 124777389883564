.displayOrder {
  position: relative;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--sportson-lightgrey-color);
  flex: 0.9;
  margin-top: 30px;
}

.displayOrder__formContainer {
  background-color: var(--sportson-lightgrey-color);
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 40px;
}

.displayOrder__formContainerMarkedForDeletion {
  background-color: red !important;
}

.displayOrder__inputText {
  width: 350px !important;
  background-color: white;
}

.displayOrder__inputContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
  align-items: center;
  border-top: 1px;
  padding-top: 460px;
}

.displayOrder__inputContainer > input {
  width: 100%;
  height: 50px;
  margin-bottom: 30px;
  margin-left: -2px;
  background-color: white;
}

.search__inputContainer > input,
input::-webkit-input-placeholder {
  font-size: 20px;
  line-height: 3;
}

.displayOrder__searchBtn {
  height: 56px;
  width: 90px;
  background-color: var(--sportson-yellow-color);
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: var(--sportson-darkgrey-color);
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.displayOrder__larsson__div {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  background-color: var(--sportson-lightgrey-color);
}