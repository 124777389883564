.orderhantering {
  position: relative;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.orderhantering__lastOrderChange {
  position: absolute;
  top: 20px;
  left: 30px;
  color: var(--sportson-grey-color);
  font-size: 16px;
}

.orderhantering__inputText {
  width: 600px !important;
  border: 1px solid rgb(196, 196, 196) !important;
  background-color: var(--sportson-lightgrey-color) !important;
  margin-top: 40px;
  padding-left: 20px;
  font-size: 18px !important;
}

.orderhantering__inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.orderhantering__inputContainer > input {
  height: 40px;
  margin-bottom: 20px;
  background-color: white;
}

.orderhantering__select {
  width: 170px;
  height: 44px;
  border-color: rgb(196, 196, 196);
  background-color: var(--sportson-lightgrey-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  -webkit-appearance: menulist;
  font-size: 16px;
  color: black;
  padding-left: 10px;
  margin-top: 20px;
}

.react-datepicker__input-container,
.react-datepicker-wrapper{
  width: 70% !important;
}

.orderhantering__customDatePickerWidth > div > div.react-datepicker__input-container input {
  width: 95px;
}

.orderhantering__customDatePickerWidth {
  position: relative;
  top: -18px;
  left: -30px;
  width: 105px !important;
}

.orderhantering__customDatePickerWidth > label {
  position: relative;
  top: -2px;
  left: 20px;
}

hr {
  margin-top: -60px;
  border-top: var(--sportson-lightgrey-color) !important;
}

.orderhantering__searchBtn {
  height: 46px;
  width: 90px;
  background-color: var(--sportson-yellow-color);
  border: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: var(--sportson-darkgrey-color);
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
}

.orderhantering__filter {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid var(--sportson-lightgrey-color);
  margin-top: 5px;
}

.orderhantering__filterSearchSelect {
  position: relative;
  left: -75px;
  width: 225px !important;
}

.orderhantering__filterSearchBtn {
  position: relative;
  top: -8px;
  background-color: white;
  border: 1px solid black;
  color: var(--sportson-darkgrey-color);
  border-radius: 5px;
  height: 50px;
  width: 100px;
  padding-right: 5px;
  padding-left: 5px;
}

.orderhantering__filterOpt {
  margin-top: 5px;
}

.orderhantering__inputTypeDate {
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.orderhantering__filterSearchDiv {
  margin-top: 10px;
  display: flex;
  justify-items: baseline;
  align-items: center;
}

.orderhantering__filterSearchDiv > label {
  position: relative;
  top: -40px;
  left: 105px;
}

.orderhantering__cleanUpFilterButton {
  position: relative;
  top: -8px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid black;
  height: 50px;
  width: 100px;
  margin-left: 5px;
}

.orderhantering__horizontalLine {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 2px;
  width: 100%;
  background-color: lightgrey;
}

.orderhantering__orderList {
  width: 100%;
}

.orderhantering__orderListExpandHead {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.orderhantering__orderListExpandHead > h4 {
  margin-left: 50px;
  font-size: 16px;
}

.orderhantering__expandIcons {
  transform: scale(2);
}

.orderhantering__expandButtons {
  margin-right: 50px;
  border: none;
  background: none;
  outline: none;
}

.orderhantering__expandButtons:focus {
  outline: none;
}

th > h5 {
  font-size: 16px;
}

.orderhantering__orderListOrders{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
  margin-left: 50px;
}

.orderhantering__orderListOrders > tr {
  height: 40px;
  padding-left: 25px;
  border-bottom: 1px solid grey;
}

.orderhantering__rowColumnsHeader {
  height: 50px !important;
}

.orderhantering__rowColumns {
  text-align: left;
  width: 225px;
  min-width: 160px;
  height: 30px;
  vertical-align: middle;
}

.orderhantering__rowColumns > h4,
.orderhantering__rowColumns > h6,
.orderhantering__inputText::placeholder {
  font-size: 16px;
}

.orderhantering__orderLink {
  position: relative;
  top: 5px;
  color: black;
  font-size: 16px;
}

.orderhantering__orderLink:hover {
  color: var(--sportson-grey-color) !important;
  cursor: pointer;
}

.orderhantering__invoice {
  position: relative;
  top: 1px;
  width: 155px;
}

.orderhantering__invoiced {
  background-color: #40B0A6;
}

.orderhantering__pageButton {
  border-radius: 10px;
  font-size: 20px;
  width: 50px;
  height: 50px;
  background-color: var(--sportson-lightgrey-color);
  border: 2px solid var(--sportson-grey-color);
  margin: 5px;
}

.orderhantering__currentPage {
  text-decoration: underline;
}

.orderhantering__tableContainer {
  position: relative;
  height: 450px;
}

.orderhantering__tr:hover {
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-bottom: 1px solid black;
  cursor: pointer;
}

.orderhantering__loadingDiv {
  margin-top: 200px;
  margin-bottom: 200px;
}
.orderhantering__loader {
  border: 16px solid var(--sportson-lightgrey-color);
  border-top: 16px solid var(--sportson-yellow-color);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.orderhantering__sortButton {
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
  font-size: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 1400px) {

  .orderhantering__orderListOrders {
    transform: scale(0.75, 0.95);
  }
}




