.app {
  width: 100%;
  min-width: 1000px;
}

.app__nav {
  flex: 0.1;
}

.app__container {
  margin-left: 225px;
  /* flex: 0.8; */
}

.app__container.inactive {
  margin-left: 80px;
}

@media (max-width: 810px) {
  .app__container {
    margin-left: 0;
  }
}