.supportTickets {
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--sportson-lightgrey-color);
    flex: 0.9;
}

.supportTickets > h5 {
    margin-top: 80px;
}