.calendar {
  position: absolute;
  margin-top: 60px;
  margin-left: -120px;
  z-index: 1000;
  box-shadow: 1px 1px 2px 1px rgba(0.1, 0.1, 0.1, 0.1);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day-name {
  background-color: var(--sportson-yellow-color) !important;
}

.react-datepicker {
  border: none !important;
  height: 300px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-radius: 5px !important;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: none !important;
}

.react-datepicker__day-names {
  padding-top: 20px !important;
}

.calendar__doneBtn {
  position: absolute;
  margin-left: 200px;
  border: none;
  background-color: transparent;
  border-radius: 5px;
  font-weight: 700;
}

.calendar__doneBtn:focus {
  background-color: rgb(59, 59, 59);
  color: white;
  padding: 5px;
  width: 60px;
  font-weight: 700;
}

.calendar__btnContainer {
  display: flex;
  align-items: center;
  background-color: white;
  margin-top: -30px;
}

.calendar__closeBtn {
  position: absolute;
  margin-left: 30px;
  border: none;
  background-color: transparent;
  border-radius: 5px;
  font-weight: 700;
}

.calendar__closeBtn:focus {
  background-color: rgb(59, 59, 59);
  color: white;
  padding: 5px;
  width: 60px;
  font-weight: 700;
}
