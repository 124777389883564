.formanscykelFonsterHantering {
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.css-1g1bohn-control,
.css-1jy15rg-control {
    width: 225px !important;
}

.formanscykelFonsterHantering__title {
    margin-left: 20px;
    margin-bottom: 20px;
    font-size: 16px;
}

.formanscykelFonsterHantering__inputText {
    width: 150px;
    border: none;
    border-right: 2px solid var(--sportson-lightgrey-color);
    height: 50%;
    background-color: var(--sportson-lightgrey-color);
    float: left;
    margin-right: 20px;
}

.formanscykelFonsterHantering__inputText::placeholder {
    font-size: 16px;
}

.formanscykelFonsterHantering__filterInputContainer {
    margin-top: 40px;
}

.formanscykelFonsterHantering__sortLabel {
    border-left: 2px solid var(--sportson-lightgrey-color);
    padding-left: 10px;
}

hr {
    margin-top: -60px;
    border-top: var(--sportson-lightgrey-color) !important;
}

.formanscykelFonsterHantering__searchBtn {
    position: relative;
    top: -10px;
    background-color: white;
    border: 1px solid black;
    color: var(--sportson-darkgrey-color);
    border-radius: 5px;
    height: 50px;
    width: 125px;
    padding-right: 10px;
    padding-left: 10px;
}

.formanscykelFonsterHantering__filter {
    display: flex;
    padding-top: 20px;
    align-items: center;
    justify-content: center;
}

.formanscykelFonsterHantering__filterSearchSelect {
    position: relative;
    top: -20px;
    left: -60px;
    width: 225px !important;
    margin-left: 0 !important;
}

.formanscykelFonsterHantering__filterOpt {
    display: flex;
    margin-top: 20px;
    border-top: 2px solid var(--sportson-grey-color);
    align-items: center;
    justify-content: center;
}

.formanscykelFonsterHantering__inputTypeDate {
    position: relative;
    left: 22px;
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: center;
    width: 95px !important;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.formanscykelFonsterHantering__filterSearchDiv {
    display: flex;
    justify-items: baseline;
    margin-bottom: 10px;
}

.formanscykelFonsterHantering__filterSearchDiv > label {
    position: relative;
    top: -35px;
    left: 10px;
    color: black !important;
    width: 0;
    margin-left: 10px;
    margin-right: 10px;
}

.formanscykelFonsterHantering__filterSearchDiv > h6 {
    position: relative;
    top: -32px;
    left: 120px;
}

.formanscykelFonsterHantering__cleanUpFilterButton {
    position: relative;
    top: -13px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid black;
    height: 50px;
    width: 100px;
    margin-left: 5px;
    margin-right: 5px;
}

input[type='checkbox'] {
    accent-color: black;
    width: 15px;
    height: 15px;
}

.formanscykelFonsterHantering__checkboxText {
    position: relative;
    top: -5px;
    left: 5px;
    margin-right: 10px;
}

.formanscykelFonsterHantering__BGKALButtonsDiv {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.formanscykelFonsterHantering__BGButton,
.formanscykelFonsterHantering__KALButton,
.formanscykelFonsterHantering__OUButton {
    position: relative;
    top: -10px;
    left: 5px;
    background-color: var(--sportson-yellow-color);
    border-radius: 5px;
    padding: 5px;
    margin-left: 5px;
}

.formanscykelFonsterHantering__customDatePickerWidth,
.formanscykelFonsterHantering__customDatePickerWidth > div.react-datepicker-wrapper,
.formanscykelFonsterHantering__customDatePickerWidth > div > div.react-datepicker__input-container,
.formanscykelFonsterHantering__customDatePickerWidth > div > div.react-datepicker__input-container input {
    position: relative;
    left: -2px;
    width: 95px !important;
}

.formanscykelFonsterHantering__loadingDiv {
    margin: 0 auto;
    text-align: center;
}

.formanscykelFonsterHantering__loader {
    border: 16px solid var(--sportson-lightgrey-color);
    border-top: 16px solid var(--sportson-yellow-color);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

