.planBenefitWindowsAdmin {
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--sportson-lightgrey-color);
    flex: 0.9;
}

#gridContainer {
    margin-top: 65px;
    margin-bottom: 100%;
    height: 100%;
    width: 100%;
    padding: 20px;
}

.planBenefitWindowsAdmin tr {
    border-bottom: 1px solid lightgray !important;
}

.planBenefitWindowsAdmin__centerText {
    text-align: center !important;
}

.dx-data-row {
    white-space: pre-wrap;
}

#gridContainer .dx-datagrid .dx-datagrid-headers {
    position: sticky;
    top: 80px;
    background-color: white;
    z-index: 3000;
}

.dx-scrollbar-vertical .dx-scrollbar-hoverable,
.dx-scrollbar-vertical .dx-scrollable-scroll {
    width: 60px !important;
    height: 100px !important;
}

.dx-scrollbar-horizontal .dx-scrollbar-hoverable,
.dx-scrollbar-horizontal .dx-scrollable-scroll {
    height: 60px !important;
}

.dx-datagrid-headers {
    color: white !important;
    background-color: black !important;
}

.dx-datagrid-filter-row {
    background-color: white !important;
}