.BG {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-bottom: 20px;
}

.BG__inputText {
    width: 150px;
    border: none;
    border-right: 2px solid var(--sportson-lightgrey-color);
    height: 50%;
    background-color: var(--sportson-lightgrey-color);
    float: left;
    margin-right: 20px;
}

.BG__inputText::placeholder {
    font-size: 16px;
}

.BG__filterInputContainer {
    margin-top: 35px;
    margin-left: auto;
}

hr {
    margin-top: -60px;
    border-top: var(--sportson-lightgrey-color) !important;
}

.BG__searchBtn {
    position: relative;
    top: -18px;
    background-color: white;
    border: 1px solid black;
    color: var(--sportson-darkgrey-color);
    border-radius: 5px;
    height: 50px;
    width: 125px;
    padding-right: 10px;
    padding-left: 10px;
}

.BG__filter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.BG__filterSearchSelect {
    position: relative;
    top: -20px;
    margin-right: 20px;
    width: 450px !important;
}

.BG__filterOpt {
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding-top: 5px;
    align-items: center;
    justify-content: center;
}

.BG__filterOpt > label {
    margin-right: 10px;
    margin-left: 10px;
    color: black !important;
}
.BG__filterSearchDiv {
    display: flex;
    justify-items: baseline;
    margin-bottom: 10px;
}

.BG__filterSearchDiv > h6 {
    position: relative;
    top: -35px;
    left: 145px;
}

.BG__cleanUpFilterButton {
    position: relative;
    top: -23px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid black;
    height: 50px;
    width: 100px;
    margin-left: 5px;
}

input[type='checkbox'] {
    accent-color: black;
    width: 15px;
    height: 15px;
}

.BG__checkboxText {
    position: relative;
    top: -5px;
    left: 5px;
    margin-right: 10px;
}

.BG__horizontalLine {
    height: 1px;
    width: 100%;
    background-color: grey;
}

.BG__bgRows {
    cursor: pointer;
    height: 70px;
}

.BG__pageButtonsDiv {
    display: flex;
    justify-content: center;
}

.BG__pageButton {
    border-radius: 10px;
    font-size: 20px;
    width: 50px;
    height: 50px;
    background-color: var(--sportson-lightgrey-color);
    border: 2px solid var(--sportson-grey-color);
    margin: 30px 5px 20px 5px;
}

.BG__currentPage {
    text-decoration: underline;
}

.BG__orderList {
    width: 100%;
}

.BG__orderListUsers{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: 50px;
    margin-left: 50px;
}

.BG__orderListUsers tr {
    display: flex;
    justify-content: space-evenly;
}

.BG__orderListUsers th,
.BG__orderListUsers td {
    width: 25%;
}

.BG__searchResultText {
    margin-left: 50px;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

.BG__bgName {
    text-align: left;
    width: 100%;
}

.BG__loadingDiv {
    width: 125px;
    margin: 0 auto;
}

.BG__loader {
    border: 16px solid var(--sportson-lightgrey-color);
    border-top: 16px solid var(--sportson-yellow-color);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.BG__bgRows > td {
    padding-top: 10px;
    display: flex;
    align-items: center;
}



