.searchbar {
  display: flex;
  margin: 30px;
  padding-top: 40px;
}

.searchbar__container {
  display: flex;
  align-items: center;
  background-color: var(--sportson-lightgrey-color);
  width: 100%;
  border-radius: 999px;
  justify-content: space-between;
  height: 60px;
  border: 1px solid var(--sportson-lightgrey-color);
}

.searchbar__input {
  display: flex;
  align-items: center;
  flex: 0.75;
  padding-left: 10px;
  font-family: FSEmericLight, sans-serif;
  font-size: 14px;
  margin-left: 10px;
}

.searchbar__input > input {
  border: none;
  background-color: var(--sportson-lightgrey-color);
  outline-width: 0;
  width: 70%;
}

.searchbar__text {
  color: grey !important;
  font-size: 16px !important;
  margin-right: 20px !important;
}
