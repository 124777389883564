.body {
  font-family: FSEmericCore, sans-serif;
}

.modal__topHeader > span {
  font-size: 1.5rem !important;
  cursor: pointer !important;
  margin-right: 15px !important;
}

.modal__benefitWindowConditions {
  text-align: center;
}

.modal__benefitWindowConditions > h2,
.modal__benefitWindowConditions__condition > h5 {
  font-size: 16px;
}

.modal__benefitWindowConditions__condition > h5 {
  font-weight: bold;
}

.modal__benefitWindowConditions__condition > h6 {
  font-size: 14px;
}

.modal__benefitWindowConditionsRuleTitle {
  font-size: 16px;
  font-weight: bold;
}

.modal__benefitWindowConditions__input {
  margin: 10px !important;
  width: 75px !important;
}

.modal__benefitWindowConditions__button {
  width: 200px !important;
  height: 40px;
  margin: 20px auto;
  background-color: var(--sportson-yellow-color);
  border-radius: 5px !important;
  border: none !important;
  cursor: pointer;
}

.modal__benefitWindowConditions__parameters {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__benefitWindowConditions_checkbox, .modal__benefitWindowConditions_checkboxNoLimits  {
  margin-right: 10px;
}