.formanscykelFonsterHanteringPC {
    position: relative;
    top: 70px;
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.css-1g1bohn-control,
.css-1jy15rg-control {
    width: 225px !important;
}

.formanscykelFonsterHanteringPC__title {
    margin-left: 20px;
    margin-bottom: 20px;
    font-size: 16px;
}

.formanscykelFonsterHanteringPC__inputText {
    width: 150px;
    border: none;
    border-right: 2px solid var(--sportson-lightgrey-color);
    height: 50%;
    background-color: var(--sportson-lightgrey-color);
    float: left;
    margin-right: 20px;
}

.formanscykelFonsterHanteringPC__inputText::placeholder {
    font-size: 16px;
}

.formanscykelFonsterHanteringPC__filterInputContainer {
    margin-top: 35px;
}

.formanscykelFonsterHanteringPC__sortLabel {
    border-left: 2px solid var(--sportson-lightgrey-color);
    padding-left: 10px;
}

hr {
    margin-top: -60px;
    border-top: var(--sportson-lightgrey-color) !important;
}

.formanscykelFonsterHanteringPC__searchBtn {
    position: relative;
    top: -10px;
    background-color: white;
    border: 1px solid black;
    color: var(--sportson-darkgrey-color);
    border-radius: 5px;
    height: 50px;
    width: 90px;
    margin-left: 5px;
    margin-left: 5px;
}

.formanscykelFonsterHanteringPC__filter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.formanscykelFonsterHanteringPC__filterSearchSelect {
    position: relative;
    left: -35px;
    top: -5px;
    width: 225px !important;
    margin: 0 !important;
    margin-top: 10px !important;
}

.formanscykelFonsterHanteringPC__filterOpt {
    display: flex;
    align-items: center;
    justify-content: center;
}

.formanscykelFonsterHanteringPC__inputTypeDate {
    position: relative;
    left: 22px;
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: center;
    width: 95px !important;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.formanscykelFonsterHanteringPC__filterSearchDiv {
    display: flex;
    justify-items: baseline;
}

.formanscykelFonsterHanteringPC__filterSearchDiv > label {
    position: relative;
    top: -35px;
    left: 10px;
    color: black !important;
    width: 0;
    margin-left: 10px;
    margin-right: 10px;
}

.formanscykelFonsterHanteringPC__filterSearchDiv > h6 {
    position: relative;
    top: -32px;
    left: 120px;
}

.formanscykelFonsterHanteringPC__cleanUpFilterButton {
    position: relative;
    top: -96px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid black;
    height: 50px;
    width: 90px;
    margin-left: 5px;
    margin-right: 5px;
}

input[type='checkbox'] {
    accent-color: black;
    width: 15px;
    height: 15px;
}

.formanscykelFonsterHanteringPC__checkboxText {
    position: relative;
    top: -5px;
    left: 5px;
    margin-right: 10px;
}

.formanscykelFonsterHanteringPC__customDatePickerWidth,
.formanscykelFonsterHanteringPC__customDatePickerWidth > div.react-datepicker-wrapper,
.formanscykelFonsterHanteringPC__customDatePickerWidth > div > div.react-datepicker__input-container,
.formanscykelFonsterHanteringPC__customDatePickerWidth > div > div.react-datepicker__input-container input {
    position: relative;
    left: -2px;
    width: 95px !important;
}

.formanscykelFonsterHanteringPC__loadingDiv {
    margin: 0 auto;
    text-align: center;
}

.formanscykelFonsterHanteringPC__loader {
    border: 16px solid var(--sportson-lightgrey-color);
    border-top: 16px solid var(--sportson-yellow-color);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.formanscykelFonsterHanteringPC__checkboxDiv {
    position: relative;
    top: -30px;
}

.formanscykelFonsterHanteringPC__searchSelectsDiv {
    position: relative;
    top: -32px;
    left: -20px;
}

.formanscykelFonsterHanteringPC__searchSelectsDiv > div > h6 {
    margin-bottom: 0 !important;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

